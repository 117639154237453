.not-found {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: 2/-2;
  }
  &__title {
    display: inline-block;
    width: auto;
    max-width: 1050px;
    margin: 0 auto 40px;
    text-align: center;
    @include tablet {
      margin: 0 auto 90px;
    }
  }
  &__image {
    display: inline-block;
    width: 100%;
    max-width: 1045px;
    max-height: 584px;
  }
  &__image + .block__links {
    margin: 40px auto;
    @include tablet {
      margin: 90px auto;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
