.block--press-contact {
  background: $creme;
  padding: 50px 24px;
  margin-bottom: 40px;
  @include desktop {
    margin-bottom: 80px;
  }

  .block {
    &__content {
      grid-column: 2 / -2;
      display: grid;
      gap: $grid-gap;
      grid-template-columns: repeat(12, 1fr);
    }

    &__contactperson {
      padding: 0 0 20px 0;
    }

    .redactor {
      a {
        color: $text-color;
        text-decoration: underline;
      }
    }
    &__title {
      max-width: 8ch;
    }
  }

  .content {
    &--left,
    &--right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &--left {
      grid-column: 1 / span 12;
      @include tablet {
        grid-column: 2 / span 5;
      }
    }

    &--right {
      grid-column: span 8 / -1;
      align-items: flex-end;
      text-align: right;
      @include tablet {
        grid-column: span 5 / -2;
      }
    }
  }
}
