.navigation {
  position: fixed;
  top: 0;
  left: 0;
  height: $navigation-height;
  width: 100%;
  background: $header-background;
  transition: transform 0.2s;
  z-index: 20;
  align-items: center;
  padding: 0;
  transform: translateY(0);
  @include mobile-only {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }
  &--visible {
    transform: translateY(0);
  }
  &--hidden {
    transform: translateY(-100%);
  }

  .logo__link {
    grid-column: 2 / span 1;
    flex-shrink: 0;
    @include mobile-only {
      max-width: 65px;
    }

    .image-container {
      display: block;
      height: auto;
      max-height: 100%;
      margin: auto 0;
    }
  }

  &__button-holder {
    grid-column: -4/-2;
    display: flex;
    justify-content: flex-end;
    @include tablet {
      margin: 0 20px 0 0;
    }
  }

  &__bar-links {
    display: none;
    @include tablet {
      grid-column: 4 / span 8;
      display: flex;
      justify-content: space-between;
      align-items: end;
      flex: 1;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: $grid-gap;
    }
    @include desktop {
      padding-bottom: 0;
      font-size: 20px;
    }
  }

  &__bar-link {
    text-decoration: none;
    white-space: nowrap;
    @include font-regular;

    &:last-child {
      margin-right: 0;
    }

    @include on-event {
      text-decoration: underline;
    }
  }
}
