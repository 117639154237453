.block--share-article {
  .block__content {
    grid-column: 2/-2;
    display: flex;
    justify-content: space-between;
    gap: $grid-gap;
    @include font-light;
    font-size: 16px;
    line-height: 1.5;
    @include tablet {
      font-size: 20px;
    }
  }

  .block__published,
  .global-block__socialshare,
  .global-block__socialshare > * {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
    font-size: 14px;
  }

  .block__published {
    display: none;
    @include tablet {
      display: block;
      margin-top: 5px;
    }
  }

  .global-block__socialshare span {
    margin-right: 8px;
    font-size: 14px;
  }
}
