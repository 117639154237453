.global-block__socialshare {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: $primary-background;

  .title {
    font-weight: 700;
  }

  .store-content {
    padding: 1rem;
  }

  .socialshare__text {
    padding: 0 12px;
  }
  .socialshare__link {
    padding: 0 12px;
    text-decoration: none;
    .image-container {
      height: 24px;
    }
    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }
}
