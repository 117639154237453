// text
//
// Markup:
// <div class="content-block content-block--text grid-container padding--regular show-on-mobile block__background--yellow">
//  <div class="content-block__content {{modifier_class}}">
//   <div class="redactor">
//    <h3>Lorem ipsum dolor sit amet</h3>
//    <p><strong>Missie </strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper facilisis arcu, quis sollicitudin nunc iaculis vitae. Nullam vitae lorem interdum, lobortis enim nec, auctor tortor. Donec aliquam diam sit amet ornare viverra. Aliquam imperdiet vel erat at viverra. Curabitur pharetra mauris id aliquet interdum.<strong><br></strong></p>
//   </div>
//    <div class="content-block__links">
//      <a href="http://localhost:8080" target="" class="button primary">Button link</a>
//    </div>
//  </div>
//  <label class="dev-label">Text</label>
// </div>
//
// .align--left - align content left
// .align--center - align content center
// .align--right - align content right
//
// Style guide: 3-components.contentblocks.text
.content-block {
  &__links {
    margin-top: -16px;
  }
  .redactor + &__links {
    margin-top: 0;
  }

  &--text &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  &--text &__links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &--text &__content.align--left {
    align-items: flex-start;
    text-align: left;
    @include desktop {
      grid-column: 4 / span 6;
    }
  }
  &--text &__content.align--right {
    align-items: flex-end;
    text-align: right;
    @include desktop {
      grid-column: 6 / span 6;
    }
    .redactor {
      text-align: left;
    }
    .content-block__links {
      justify-content: flex-end;

      a {
        margin-right: 0;
        &:last-child {
          margin-left: 16px;
        }
      }
    }
  }
  &--text &__content.align--center {
    align-items: center;
    text-align: center;
    .redactor {
      text-align: left;
    }
    @include desktop {
      grid-column: 5 / span 6;
    }
  }
}
