// Image
//
// Markup:
//     <div class="image-container">
// 	     <svg class="image-shape" width="647" height="477" viewBox="0 0 647 477" fill="none" xmlns="http://www.w3.org/2000/svg">
// 		     <path d="M597.241 477L0.165039 388.122L26.5568 291.713L57.723 0L613.687 74.2705L646.165 329.876L597.241 477Z" fill="#FBF1BF"/>
// 	     </svg>
//       <picture class="image">
//         <img src="https://source.unsplash.com/random/500x500" width="500" height="500" style="" loading="lazy" decoding="async">
//       </picture>
//     </div>
//    </div>
//
// Style guide: 3-components.image

.image-container {
  position: relative;
  display: inline-flex;
  width: 100%;
  .image {
    display: flex;
    width: -webkit-fill-available;
    width: inherit;
    max-height: 100%;
    max-width: 100%;
    &.has-bg {
      padding: 7%;
    }
    &-shape {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }
    svg,
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      content-visibility: auto;
    }
  }
}
