.block--in-the-media {
  .block {
    &__content {
      position: relative;
      grid-column: 2 / -2;
      display: flex;
      flex-direction: column;
    }

    // Header
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      padding: 0 0 40px 0;
    }
    &__header__title {
      position: relative;
      display: inline-block;
    }
    &__header__title .title {
      position: relative;
      display: inline-block;
      line-height: 0.8;
      z-index: 1;
      word-break: normal;
    }
    &__header__title .title__shape {
      position: absolute;
      left: 0;
      bottom: 0%;
      height: 40px;
      width: 100%;
      transform: scaleX(-1);
      z-index: 0;
    }

    &__media-article-list {
      display: flex;
      flex-direction: column;
      @include tablet {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;
      }
    }
  }
}
