// Quote
//
// Markup:
// <div class="article-block article-block--quote grid-container padding--regular show-on-mobile">
//   <div class="article-block__content">
//     <div class="quote">
//       <svg class="quote__shape" viewBox="0 0 1344 692" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
//         <path d="M1344 0V614.196L1273.59 692L568.615 605.648L81.2255 648.232L0 0L675.446 27.2068L1344 0Z" fill="#FBF1BF"></path>
//       </svg>
//       <p class="quote__quote big--title">“More importantly, the company is really accelerating in the wake of our successful 150 million euro capital raise last February.”</p>
//     </div>
//     <p class="quote__quotee">Michiel Langezaal - CEO Fastned</p>
//   </div>
// 	<label class="dev-label">Quote</label>
// </div>
//
// Style guide: 3-components.articleblocks.quote

.article-block--quote {
  position: relative;

  .article-block__content {
    @include desktop {
      grid-column: 4 / -4;
    }
  }

  .quote {
    position: relative;

    &__shape {
      display: none;
      @include desktop {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__quote {
      position: relative;
      display: inline-block;
      border: none;
      padding: 20px 20px;
      @include font-medium;
      text-align: center;
      @include tablet {
        padding: 50px 20px;
      }
      @include desktop {
        padding: 150px 40px;
      }
    }

    &__quotee {
      display: block;
      position: relative;
      text-align: center;
      margin-top: 0;
      @include tablet {
        margin-top: -15px;
      }
      @include desktop {
        margin-top: -40px;
      }
    }
  }
}
