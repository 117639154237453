.navigation-menu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 14;
  width: 100%;
  background: $creme;
  min-height: 100vh;
  height: 100%;
  transform: translateY(0);
  overflow: overlay;
  padding: calc(#{$navigation-height} + 40px) 0 80px 0;
  display: flex;
  @include tablet {
    display: grid;
  }
  @include desktop {
    grid-column: 2/-2;
  }

  &:not(.navigation-menu--open) {
    transform: translateY(-100%);
    .navigation__menu-links,
    .langue-dropdowns__container {
      display: none;
    }
  }

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    grid-column: 2 / -2;
    height: auto;
    @include tablet {
      grid-column: 4 / span 8;
    }
    @include widescreen {
      grid-column: 4 / span 8;
    }
  }

  .langue-dropdowns__container {
    display: grid;
    width: 100%;
    max-width: unset;
    margin: 36px 0 0 0;
    padding: 0 40px;
    @include tablet {
      gap: $grid-gap;
      grid-template-columns: 1fr;
      padding: 0;
    }
    @include desktop {
      grid-template-columns: 4fr 4fr;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 50px 0 0 0;
    }

    .v-select .vs__dropdown-menu,
    .v-select.vs--open .vs__dropdown-menu,
    .v-select.vs--disabled .vs__dropdown-menu {
      position: relative;
    }
  }
}

.navigation {
  &__menu-links {
    margin: 0;
    height: max-content;
    display: grid;
    padding: 0 40px;
    @include tablet {
      gap: $grid-gap;
      grid-template-columns: 4fr 4fr;
      padding: 0;
    }
    @include desktop {
      grid-template-columns: 4fr 4fr;
    }
  }

  &__menu-link {
    margin-bottom: 1em;
    font-size: 20px;
    font-weight: 400;
    height: max-content;
    text-decoration: none;
    @include on-event {
      text-decoration: underline;
    }
    @include desktop {
      font-size: 20px;
    }
    &--mobile {
      display: block;
      @include tablet {
        display: none;
      }
    }
  }
}
