.block--featured-articles {
  .block {
    &__content {
      position: relative;
      grid-column: 2 / -2;
      display: flex;
      flex-direction: column;
    }
    // Header
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 70%;
      padding: 0 0 40px 0;
    }
    &__header__title {
      position: relative;
      display: inline-block;
      @include tablet {
        max-width: 25em;
      }
    }
    &__header__title .title {
      position: relative;
      display: inline-block;
      line-height: 0.8;
      z-index: 1;
      word-break: normal;
    }
    &__header__title .title__shape {
      position: absolute;
      left: 0;
      bottom: 0%;
      height: 40px;
      width: 100%;
      transform: scaleX(-1);
      z-index: 0;
    }
  }

  .block__article-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 200px);
    }
    @include desktop {
      display: grid;

      grid-template-columns: repeat(3, 1fr);
    }
    @include desktop-medium {
      grid-template-columns: minmax(30%, 1fr) minmax(30%, 1fr) minmax(
          auto,
          663px
        );
    }

    .featured-article {
      &:nth-child(3n + 1) {
        height: 250px;
        @include tablet {
          grid-column: auto / span 2;
          grid-row: auto / span 1;
          height: 260px;
        }
        @include desktop {
          height: 100%;
          grid-row: auto / span 2;
        }
      }
      &:nth-child(3n + 2),
      &:nth-child(3n + 3) {
        height: 250px;
        @include tablet {
          grid-column: auto / span 1;
          grid-row: auto / span 1;
          height: 200px;
        }
      }
    }
  }
}
