// MultiColumn
//
// Markup:
// <div class="content-block content-block--multi-column grid-container padding--regular show-on-mobile block__background--yellow">
//  <div class="content-block__content">
//    <div class="columns">
//      <div class="column order-2">
//        <div class="image-container">
//          <svg class="image-shape" width="647" height="477" viewBox="0 0 647 477" fill="none" xmlns="http://www.w3.org/2000/svg">
//            <path d="M597.241 477L0.165039 388.122L26.5568 291.713L57.723 0L613.687 74.2705L646.165 329.876L597.241 477Z" fill="#FBF1BF"></path>
//          </svg>
//          <picture class="image">
//            <img src="https://source.unsplash.com/random/480x300" width="480" height="300">
//          </picture>
//        </div>
//        <div class="block__text">
//          <div class="redactor h5">
//            <p><strong>Text 1</strong> Maecenas ullamcorper facilisis arcu, quis sollicitudin nunc iaculis vitae</p>
//          </div>
//          <a href="http://localhost:8080/nl/nl/" target="" class="button button--secondary">Button text</a>
//        </div>
//      </div>
//      <div class="column order-3">
//        <div class="image-container">
//          <svg class="image-shape" width="647" height="477" viewBox="0 0 647 477" fill="none" xmlns="http://www.w3.org/2000/svg">
//            <path d="M597.241 477L0.165039 388.122L26.5568 291.713L57.723 0L613.687 74.2705L646.165 329.876L597.241 477Z" fill="#FBF1BF"></path>
//          </svg>
//          <picture class="image">
//            <img src="https://source.unsplash.com/random/480x300" width="480" height="300">
//          </picture>
//        </div>
//        <div class="block__text">
//          <div class="redactor h5">
//            <p><strong>Text 2</strong> Maecenas ullamcorper facilisis arcu, quis sollicitudin nunc iaculis vitae</p>
//          </div>
//          <a href="http://localhost:8080/nl/nl/" target="" class="button button--secondary">Button text</a>
//        </div>
//      </div>
//      <div class="column order-1">
//        <div class="image-container">
//          <svg class="image-shape" width="647" height="477" viewBox="0 0 647 477" fill="none" xmlns="http://www.w3.org/2000/svg">
//            <path d="M597.241 477L0.165039 388.122L26.5568 291.713L57.723 0L613.687 74.2705L646.165 329.876L597.241 477Z" fill="#FBF1BF"></path>
//          </svg>
//          <picture class="image">
//            <img src="https://source.unsplash.com/random/480x300" width="480" height="300">
//          </picture>
//        </div>
//        <div class="block__text">
//          <div class="redactor h5">
//            <p><strong>Text 3</strong> Maecenas ullamcorper facilisis arcu, quis sollicitudin nunc iaculis vitae</p>
//          </div>
//          <a href="http://localhost:8080/nl/nl/" target="" class="button button--secondary">Button text</a>
//        </div>
//      </div>
//    </div>
//  </div>
//	<label class="dev-label">Multi column</label>
//</div>
//
// Style guide: 3-components.contentblocks.multiColumn

.content-block--multi-column {
  .content-block__content {
    grid-column: 4 / -4;
  }

  .columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
    @include tablet {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 2 * $grid-gap;
      justify-content: space-between;
    }
    @include landscape-mobile {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 2 * $grid-gap;
    }
  }

  .column {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 20px;
    @include mobile-only {
      .block--without-content {
        display: none;
      }
      @for $i from 1 through 10 {
        &.order-#{$i} {
          order: #{$i};
        }
      }
    }
    @include tablet {
      flex-direction: row;
      margin-bottom: 0px;
      flex-direction: column;
      @for $i from 1 through 4 {
        &.col-#{$i} {
          width: calc(((100% - #{($i - 1) * $grid-gap}) / #{$i}) * 1);
        }
      }
      &.col-3 {
        width: calc(((100% - #{2 * $grid-gap}) / 4) * 1);
      }
      &.col-4 {
        width: calc(((100% - #{3 * $grid-gap}) / 5) * 1);
      }
    }

    .image-container,
    .redactor {
      margin-right: 10px;
      @include tablet {
        margin-right: 0;
      }
      @include desktop {
        margin-bottom: 44px;
      }
    }

    .image-container {
      height: auto;
      width: 100%;
      align-self: center;
    }
    &.col-2 .image-container {
      max-width: 414px;
    }
    &.col-3 .image-container {
      max-width: 243px;
      max-height: 236px;
    }

    .block__text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 1;
      font-size: inherit;
      @include tablet {
        width: 100%;
      }
      @include mobile-only {
        h2,
        .h2 {
          font-size: 22px;
        }
      }
      @include until-desktop {
        h3,
        .h3,
        h4,
        .h4 {
          font-size: 22px;
        }
      }

      h2 {
        @extend .semi-big--title;
      }

      .button {
        width: max-content;
      }
    }
  }
}
