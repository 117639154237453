// Video

// Style guide: 3-components.video

.video-container {
  display: block;
  position: relative;
  width: 100%;
  max-width: 1000px;
  height: 0;
  padding: 56.5% 0 0 0;
  margin: 16px auto 20px;
  @include desktop {
    margin-bottom: 40px;
  }

  .video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}