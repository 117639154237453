.block--related-articles {
  position: relative;
  padding: 76px 24px;
  margin-bottom: 40px;
  @include desktop {
    margin-bottom: 80px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $creme;
  }

  .block__content {
    grid-column: 2/-2;
    z-index: 1;
  }

  .block__title {
    margin-bottom: 65px;
    z-index: 1;
  }

  .block__list {
    display: flex;
    flex-direction: column;
    gap: 25px;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @include desktop {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(2, 200px);
    }

    .related-article {
      &:nth-child(3n + 1) {
        @include tablet {
          height: 84px;
        }
        @include desktop {
          height: 100%;
          grid-row: 1 / span 2;
          grid-column: 1 / span 7;
        }
      }
      &:nth-child(3n + 2),
      &:nth-child(3n + 3) {
        @include tablet {
          height: 84px;
        }
        @include desktop {
          height: 200px;
          grid-column: span 5;
        }
      }
    }
  }
}
