.featured-article {
  position: relative;
  text-decoration: none;

  .image-container {
    .image img {
      object-fit: cover !important;
    }
  }

  &__content-container {
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 10%,
      rgba(0, 0, 0, 0) 40%
    );
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 14px;
    bottom: 0;
    @include tablet {
      padding: 24px;
    }
  }

  &__title {
    color: $white;
    margin: 0;
  }

  &__date {
    color: $white;
    margin: 0;
    padding: 0;
    font-size: 16px;
    @include font-light;
    @include desktop {
      font-size: 20px;
    }
  }
}
