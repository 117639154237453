.media-item {
  display: flex;
  align-items: center;
  @include tablet {
    flex-direction: column;
    align-items: start;
  }

  &__image-container {
    padding: 0;
    width: calc(50% - 10px);
    @include tablet {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 10px;
    width: 50%;
    @include tablet {
      width: 100%;
      padding: 20px 0 0 0;
    }
    @include desktop {
      align-items: flex-start;
    }

    a {
      text-decoration: none;
    }
  }

  &__title {
    margin: 0;
    @include tablet-only {
      margin: 0 0 20px 0;
      font-size: 18px;
      font-weight: 400;
    }
    @include desktop {
      margin: 0 0 50px 0;
    }
  }

  &__details {
    display: none;
    @include tablet {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__date,
  &__author {
    padding: 0;
    margin: 0;
    @include desktop {
      font-size: 14px;
    }
  }

  .button {
    width: max-content;
  }
}
