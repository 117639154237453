// socialFeed
//
// Markup:
// <div class="content-block content-block--social-feed grid-container padding--regular show-on-mobile block__background--yellow">
//   <div class="content-block__content">
//     <h2 class="block__title semi-big--title">See them in the wild</h2>
//     <div class="image-container block__image">
//       <picture class="image transparent " data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/Test-content/_100xAUTO_crop_center-center_10_none/Homepage-SocialFeed.png" data-alt="Screenshot 2022 01 11 at 10 32 18">
//         <img src="https://source.unsplash.com/random/1024x446" width="1024" height="446" style="" loading="lazy" decoding="async">
//       </picture>
//     </div>
//   </div>
//   <label class="dev-label">Social feed</label>
// </div>
//
// Style guide: 3-components.contentblocks.socialFeed

.content-block {
  &--social-feed &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--social-feed .block__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 0 0;
    @include desktop {
      margin: 80px 0 0 0;
    }
  }

  &--social-feed .block__image {
    width: 100%;
    height: auto;
    flex: 1;
    margin: 25px 0 0 0;
    @include tablet {
      margin: 0;
    }

    &.social__image1 {
      margin: -25px 0 0 0;
      @include tablet {
        margin: 25px 20px 0 0;
      }
    }

    &.social__image2 {
      flex: 1;
      margin: 0 0 0 20px;
      @include tablet {
        flex: 1.5;
        margin: -25px 0 0 0;
      }
    }

    &.social__image3 {
      margin: 25px 0 0 20px;
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
}
