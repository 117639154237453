// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue',
  'Helvetica', 'Arial', sans-serif !default;
$title-font-stack: $text-font-stack;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
  'Monaco', monospace !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;

// Layout settings
$grid-width: 1080px;
$grid-gap: 20px;
$grid-gap--mobile: 12px;
$grid-column-count: 12;
$grid-column-count--mobile: 12;

$container-padding: 24px;
$container-padding-mobile: 24px;

$article-width: 708px;
$navigation-height: 100px;
$footer-height: 100px;
$footer-height-tablet: 74px;
