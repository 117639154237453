// Base
//
// Introduction
//
// In this folder, basic global styling will be defined, to help building other components of the site.
// This page will include de basic styling of the following subjects
// - Buttons
// - Links
// - Grid layout
// - Typograhy
// - etc
//
// Style guide: 2-base.base

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
* {
  outline-color: $dark-border;
}

html,
body {
  background: $brand-color;
  box-sizing: border-box;
  word-break: break-word;
}
body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  scroll-behavior: smooth;
  padding-top: 100px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

.seo-title {
  display: none;
}
