.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  &:not(.splash-screen--open) {
    display: none;
  }

  .splash-content {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    width: 100vw;
    height: 87vh;
    margin: 13vh 0 0 0;
    background: $yellow;
    padding: 0 50px;
    @include tablet {
      height: 95vh;
      margin: 5vh 0 0 0;
    }
    @include desktop{
      height: 92vh;
      margin: 8vh 0 0 0;
      padding: 0;
    }

    .close-button {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .splash-title {
      text-align: center;
      margin: 200px 0 100px 0;
    }

    .langue-dropdowns__container {
      @include tablet {
        gap: 170px;
      }
    }
  }
}
