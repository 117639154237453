// Search result
.results--item {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px $grid-gap;
  .results__header {
    grid-column: 1/-1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 20px;
    @include tablet {
      flex-direction: row;
      margin-bottom: 0;
    }
    .header__link {
      margin: 0 25px;
      white-space: nowrap;
      @include mobile-only {
        margin: 0;
      }
    }
    .header__title {
      word-break: break-word;
      width: 100%;
      text-align: center;
      width: auto;
      flex: 1;
      @include tablet {
        text-align: left;
      }
      @include desktop {
        margin: 0;
        max-width: 70%;
        text-align: left;
      }
    }
  }

  .results__details {
    &.results__connector {
      grid-row: 2;
      grid-column: 1 / span 6;
      @include tablet {
        grid-row: 2;
        grid-column: 1 / span 5;
      }
    }
    &.results__charger {
      grid-row: 3;
      grid-column: -7 / span 6;
      @include tablet {
        grid-row: 2;
        grid-column: -6 / span 5;
      }
    }
  }
}

.results__details {
  text-align: center;
  @include tablet {
    margin-top: 30px;
  }
  @include desktop {
    margin-top: 50px;
  }

  &.results__connector {
    .image-container .image-shape {
      transform: rotate(180deg);
    }
  }

  .image-container {
    height: 90px;
    width: 100%;
    margin-bottom: 20px;
    @include tablet {
      height: 160px;
      margin-bottom: 30px;
    }
    @include desktop {
      height: 286px;
      margin-bottom: 36px;
    }
    picture {
      width: 100%;
      img {
        object-fit: contain;
      }
    }
  }

  .detail__title {
    margin-bottom: 10px;
    @include tablet {
      margin-bottom: 20px;
    }
    @include desktop {
      margin-bottom: 44px;
    }
  }

  .detail__type {
    @include font-regular;
    width: 90%;
    margin: 0 auto;
  }
}

.results__description {
  grid-row: 4;
  grid-column: 3 / span 9;
  text-align: right;
  @include tablet {
    grid-column: -6 / span 5;
    text-align: center;
  }
}
