// AppStoreDownloadExtended
//
// Markup:
// <div class="content-block content-block--app-store-download-extended grid-container padding--regular show-on-mobile block__background--yellow">
//   <div class="content-block__content">
//     <div class="content-block__image-container">
//       <div class="image-container content-block__image">
//         <picture class="image fit" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-09.24.19.png" data-alt="Screenshot 2022 01 05 at 09 24 19">
//           <img src="https://picsum.photos/528/660" width="528" height="660" style="" loading="lazy" decoding="async">
//         </picture>
//       </div>
//     </div>
//     <div class="content-block__text-container">
//       <h2 class="content-block__title h2">Start from the Fastned app.</h2>
//     </div>
//     <div class="content-block__second-text-container">
//       <div class="content-block__second-text">
//         <div class="image-container content-block__second-text__image-container">
//           <picture class="image fit" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-10.26.31.png" data-alt="Screenshot 2022 01 05 at 10 26 31">
//             <img src="https://picsum.photos/250/250" width="250" height="250" style="" loading="lazy" decoding="async">
//           </picture>
//         </div>
//         <h2 class="content-block__second-text__title h5">Charge instantly with autocharge</h2>
//       </div>
//       <div class="content-block__usps-container">
//         <ul class="content-block__usps">
//           <li class="content-block__usps__item">
//             <div class="image-container content-block__usps__image">
//               <picture class="image fit" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-10.26.31.png" data-alt="Screenshot 2022 01 05 at 10 26 31">
//                 <img src="https://picsum.photos/60/60" width="60" height="60" style="" loading="lazy" decoding="async">
//               </picture>
//             </div>
//             <p class="content-block__usps__title h5">Check charger availability</p>
//           </li>
//           <li class="content-block__usps__item">
//             <div class="image-container content-block__usps__image">
//               <picture class="image fit" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-10.26.31.png" data-alt="Screenshot 2022 01 05 at 10 26 31">
//                 <img src="https://picsum.photos/60/60" width="60" height="60" style="" loading="lazy" decoding="async">
//               </picture>
//             </div>
//             <p class="content-block__usps__title h5">Plan every stop on your next Eurotrip</p>
//           </li>
//         </ul>
//       </div>
//     </div>
//     <div class="content-block__app-store-container">
//       <div class="block__appstore appstore--full">
//         <div class="block__appstore__content">
//           <div class="block__appstore__qr">
//             <a href="https://apps.apple.com/nl/app/fastned/id1485702761" target="_blank">
//               <div class="image-container ">
//                 <picture class="image  block__appstore__qr__image" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-10.26.31.png" data-alt="Screenshot 2022 01 05 at 10 26 31">
//                   <img src="https://picsum.photos/126/126" width="126" height="126" style="" loading="lazy" decoding="async">
//                 </picture>
//               </div>
//             </a>
//           </div>
//           <div class="block__appstore__text">
//             <p class="block__appstore__title">Download the app</p>
//             <div class="redactor block__appstore__redactor">
//               <p>Use your phone's camera to download the free Fastned app.</p>
//               <p>Available for <a href="https://apps.apple.com/nl/app/fastned/id1485702761" target="_blank" rel="noreferrer noopener">iOS</a> in <a href="https://play.google.com/store/apps/details?id=nl.fastned.my&amp;hl=nl&amp;gl=US" target="_blank" rel="noreferrer noopener">Android</a>.</p>
//             </div>
//           </div>
//         </div>
//         <div class="block__appstore__link">
//           <a href="https://apps.apple.com/nl/app/fastned/id1485702761" target="_blank" class="button button--secondary">Download the app</a>
//         </div>
//       </div>
//     </div>
//   </div>
//   <label class="dev-label">App store download extended</label>
// </div>
//
// Style guide: 3-components.contentblocks.appStoreDownloadExtended

.content-block {
  &--app-store-download-extended &__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 0 $grid-gap;
    grid-template-columns: repeat(7, 1fr);
    @include tablet {
      display: grid;
      grid-template-areas:
        'block_image    block_image   block_image   block_image   block_text      block_text      block_text'
        'block_image    block_image   block_image   block_image   block_text      block_text      block_text'
        'block_second   block_second  block_second  block_second  block_second    block_second    block_second'
        'block_second   block_second  block_second  block_second  block_second    block_second    block_second'
        'block_store    block_store   block_store   block_store   block_store     block_store    .'
        'block_store    block_store   block_store   block_store   block_store     block_store    .';
    }
    @include desktop-medium {
      display: grid;
      grid-template-areas:
        'block_image    block_image   block_image   block_image  . block_text    block_text    block_text'
        'block_image    block_image   block_image   block_image  . block_text    block_text    block_text'
        'block_image    block_image   block_image   block_image  . block_second  block_second  block_second'
        'block_store    block_store   block_store   block_store  . block_second  block_second  block_second'
        'block_store    block_store   block_store   block_store  . block_second  block_second  block_second'
        'block_store    block_store   block_store   block_store  . block_second  block_second  block_second';
    }
  }

  &--app-store-download-extended &__image-container {
    height: 100%;
    width: calc(50% - (#{$grid-gap} / 2));
    @include tablet {
      grid-area: block_image;
      width: 100%;
    }
  }
  &--app-store-download-extended &__text-container {
    align-self: flex-end;
    width: calc(50% - (#{$grid-gap} / 2));
    @include tablet {
      grid-area: block_text;
      align-self: center;
      width: 100%;
      padding-top: calc(3 * #{$grid-gap});
    }
    @include desktop {
      padding-top: calc(6 * #{$grid-gap});
    }
  }
  &--app-store-download-extended &__title {
    max-width: 15ch;
  }

  // {# Second Content Text #}
  &--app-store-download-extended &__second-text-container {
    grid-area: block_second;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: $grid-gap;
    @include tablet {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }
    @include desktop-medium {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  &--app-store-download-extended &__second-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    flex: 1;
    width: 50%;
    @include tablet {
      max-width: 140px;
      margin-right: 60px;
    }
    @include desktop {
      width: auto;
      max-width: unset;
    }
  }
  &--app-store-download-extended &__second-text__title {
    @include font-light;
    text-align: center;
    max-width: 132px;
    margin: 0 auto 20px;
    @include tablet {
      max-width: 250px;
    }
  }

  &--app-store-download-extended &__second-text__image-container {
    max-width: 132px;
    margin: 0 auto 20px;
    @include tablet {
      max-width: 250px;
    }
  }
  // {# USPS #}
  &--app-store-download-extended &__usps-container {
    flex: 1;
    width: 50%;
    @include tablet {
      width: 100%;
      max-width: 250px;
    }
    @include desktop-medium {
      max-width: 350px;
    }
  }
  &--app-store-download-extended &__usps {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &--app-store-download-extended &__usps__item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    @include font-light;
    margin: 0;
    margin-bottom: 0.5em;
    @include desktop {
      min-width: calc(250px + 60px + 30px);
    }
  }
  &--app-store-download-extended &__usps__item > * {
    @include font-light;
    margin: 0;
  }
  &--app-store-download-extended &__usps__title {
    flex: 1;
    max-width: 250px;
  }
  &--app-store-download-extended &__usps__image {
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    @include desktop-medium {
      width: 60px;
      height: 60px;
    }
  }

  // {# App Store #}
  &--app-store-download-extended &__app-store-container {
    display: flex;
    justify-content: center;
    width: 50%;
    overflow: visible;
    @include tablet {
      justify-content: flex-start;
      margin-top: 20px;
    }
    .appstore {
      display: block;
      overflow: visible;
    }
    .button {
      white-space: nowrap;
    }
    @include tablet {
      grid-area: block_store;
      width: 100%;
    }
  }
}
