.block--api-search {
  .block__content {
    grid-column: 2/14;
  }

  .search {
    margin-bottom: 25px;

    .search__form {
      width: 100%;
    }

    .search__input {
      width: 100%;
      padding: 8px 16px;
    }
  }

  .results {
    &--loading-state,
    &--results-list {
      display: flex;
      flex-direction: column;

      .results__header {
        margin-bottom: 25px;
        font-size: 30px;
      }

      .results__list {
        list-style: none;
      }

      .results__list-item {
        margin-bottom: 25px;
      }
    }
  }
}
