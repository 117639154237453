.loading-state {
  text-align: center;
  color: $assets-color;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.loader {
  font-size: 10px;
  position: relative;
  border-top: 4px solid rgba(0, 0, 0, 0.1);
  border-right: 4px solid rgba(0, 0, 0, 0.1);
  border-bottom: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid $brand-color;
  transform: translateZ(0);
  animation: spinner 1.1s infinite linear;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
