$form-error: red;
$form-success: #5c9d65;
$form-instructions: black;

.freeform {
  font-weight: 400;
  color: black;
  ul:not(.freeform-pages),
  ol:not(.freeform-pages) {
    padding: 1em 1em 1em 2em;
  }
  &-pages {
    border-bottom: 2px solid black;
  }
  &-pages &-page {
    border-radius: 0;
    padding: 10px 40px;
    border: 2px solid black;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    cursor: not-allowed;
    &--active {
      background: $creme;
      @include font-bold;
    }
  }
  &-row {
  }
  // Label
  &-row &-column &-label {
    font-weight: 400;
  }
  &-row &-column &-label.freeform-required {
  }
  &-row &-column &-label.freeform-required:after {
    color: black;
  }

  // Input
  &-row &-column &-input {
    background: $creme;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px 10px;
    font-size: 16px;
    outline: none;
    color: black;
    @include desktop {
      border: 2px solid black;
    }
  }
  &-row &-character-limit {
    position: relative;
    margin-bottom: 1em;
    &::after {
      content: attr(data-content);
      display: block;
      position: absolute;
      right: 0;
      left: auto;
      bottom: 0;
      color: black;
      transform: translate(-0%, 65%);
      font-size: 16px;
      line-height: 1.15;
      @include desktop {
        font-size: 20px;
        line-height: 1.2;
      }
    }
  }
  &-row &-column textarea.freeform-input {
    padding: 20px 10px;
  }

  // Opinionscale
  &-row &-opinion_scale .opinion-scale-scales {
    li {
      list-style: none;
      font-weight: 400;
      font-size: 16px;
      display: flex;
    }
  }
  // Uploads
  &-row &-column input[type='file'] {
    position: relative;
    display: block;
    padding: 10px 20px 10px 20px;
    width: 100%;
    border-radius: 10px;
    padding: 10px 10px;
    cursor: pointer;
    overflow: hidden;
    @include desktop {
      border: 2px solid black;
    }

    &::-webkit-file-upload-button {
      visibility: hidden;
    }
    &::before {
      content: 'Button';
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: black;
      padding: 10px 20px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      font-weight: 700;
      color: white;
      transition: all 0.3s;
    }
    &:hover::before,
    &:active::before {
      background: $dark-border;
    }
  }
  &-row &-file {
    .freeform-file-errors {
      display: none;
      &:last-of-type {
        display: block;
      }
    }
    &.has-files {
      input[type='file']:before {
        background: $form-success;
      }
      .freeform-file-errors {
        display: none;
      }
    }
  }

  // Select
  &-row &-column select.freeform-input {
    position: relative;
    display: block;
    min-height: 45px;
    overflow: overlay;
    color: black;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background: $creme;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 27 19' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M15.0791 17.9682C14.2784 18.9984 12.7216 18.9984 11.9209 17.9682L0.852835 3.72732C-0.168269 2.4135 0.768014 0.5 2.43198 0.5L24.568 0.499999C26.232 0.499998 27.1683 2.41349 26.1472 3.72731L15.0791 17.9682Z' fill='black'></path></svg>");
    background-repeat: no-repeat;
    background-size: 14px;
    background-position-x: calc(100% - 10px);
    background-position-y: 50%;
  }
  &-row &-multiple_select select.freeform-input {
    background-image: none;
    &::-webkit-scrollbar {
      width: 14px;
    }
    &::-webkit-scrollbar-track {
      margin: 8px 0;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $dark-yellow;
      border-radius: 20px;
      border: 4px solid $creme;
    }
  }

  // radio/checkbox
  &-input-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    &:hover,
    &:focus {
      .freeform-label {
        font-weight: bold;
      }
      input ~ .freeform-mark {
        background-color: white;
      }
    }
  }
  &-input-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .freeform-mark {
      background-color: $creme;
    }
    &:checked ~ .freeform-mark:after {
      display: block;
    }
  }
  &-input-container .freeform-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5em;
    width: 1.5em;
    border: 2px solid black;
    background: $creme;
    &:after {
      content: '';
      display: none;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0.35em;
      height: 0.7em;
      border: solid black;
      border-width: 0 3px 3px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
  .radio &-input-container &-mark,
  &-radio_group &-input-container &-mark {
    border-radius: 50%;
    &:after {
      display: block;
      width: 0.7em;
      height: 0.7em;
      background: none;
      border-radius: 50%;
      border: none;
    }
  }
  .radio &-input-container &-input:checked + &-mark,
  &-radio_group &-input-container &-input:checked + &-mark {
    &:after {
      background: black;
    }
  }

  // Instructions
  &-row &-column &-instructions {
    font-size: 14px;
    font-weight: 400;
    color: $form-instructions;
  }

  // Errors
  &-row &-column &-errors {
    color: $form-error;
    font-size: 14px;
  }
  &-form-has-errors {
    color: $form-error;
  }

  &--success,
  &--fail {
    align-self: flex-start;
    width: 100%;
    max-width: 600px;
    padding: $grid-gap;
    border-radius: 10px;
    background: $creme;
    text-align: left;
    margin-top: 40px;
    @include desktop {
      padding: calc(2 * #{$grid-gap});
    }
    h1,
    h2,
    h3,
    h4 {
      @include font-semi-bold;
      text-transform: uppercase;
    }
  }
}
