.global-block__social {
  margin: 0 -12px;
  width: 100%;
  display: flex;

  > a {
    height: auto;
    text-decoration: none;
    margin: 0 8px;

    &:hover,
    &:focus {
      transform: scale(1.2);
    }

    .image-container {
      height: 24px;
      width: 24px;
      @include desktop {
        height: 36px;
        width: 36px;
      }
    }
  }
}
