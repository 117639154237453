// FAQ
//
// Markup:
// <div class="content-block content-block--faq grid-container padding--regular show-on-mobile block__background--yellow">
//   <div class="content-block__content">
//     <h2 class="block__title">Have some more questions about charging?</h2>
//     <ul class="faq-block">
//       <li class="faq-item">
//         <button class="faq-item__question question--open">
//          <h3 class="item__title">Question 1</h3>
//          <svg width="21" height="11" viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="chevron"><path d="M1.7207 2.13379L10.454 8.9029L19.2202 2.13379" stroke="black" stroke-width="2.79382" stroke-linecap="round"></path></svg>
//         </button>
//         <div class="faq-item__answer redactor">
//           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper facilisis arcu, quis sollicitudin nunc iaculis vitae. Nullam vitae lorem interdum, lobortis enim nec, auctor tortor. Donec aliquam diam sit amet ornare viverra. Aliquam imperdiet vel erat at viverra. Curabitur pharetra mauris id aliquet interdum.</p>
//         </div>
//       </li>
//       <li class="faq-item">
//         <button class="faq-item__question"><h3 class="item__title"> Question 2 <svg width="21" height="11" viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="chevron"><path d="M1.7207 2.13379L10.454 8.9029L19.2202 2.13379" stroke="black" stroke-width="2.79382" stroke-linecap="round"></path></svg></h3></button>
//       </li>
//       <li class="faq-item"><button class="faq-item__question"><h3 class="item__title"> Question 3 <svg width="21" height="11" viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="chevron"><path d="M1.7207 2.13379L10.454 8.9029L19.2202 2.13379" stroke="black" stroke-width="2.79382" stroke-linecap="round"></path></svg></h3></button>
//       </li>
//     </ul>
//   </div>
//   <label class="dev-label">Faq</label>
// </div>
//
// Style guide: 3-components.contentblocks.faq
.content-block--faq {
  .content-block__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 0 $grid-gap;
      margin-bottom: 85px;
    }
    @include desktop {
      grid-column: 4 / -4;
    }
  }

  .block__title {
    width: calc((100% / 12) * 7);
    margin-bottom: 10px;
    @include tablet {
      grid-column: 1 / span 5;
      grid-row: 1;
      width: 100%;
      margin: 0;
    }
  }
  .faq-block {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    @include tablet {
      grid-column: 6 / -1;
      grid-row: 2;
    }
  }

  .faq-item {
    margin: 0;
    border-bottom: 2px solid $dark-border;
  }

  .faq-item__question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-weight: bold;
    background: none;
    margin: 0;
    padding: 20px 0;
    border: none;
    color: $black;
    .item__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex: 1;
    }
    .chevron {
      transition: all 0.2s;
      width: 21px;
      margin-left: 20px;
    }
    &.question--open {
      .chevron {
        transform: rotate(180deg);
      }
    }
  }

  .faq-item__answer {
    padding-bottom: 20px;
    font-size: 14px;
    @include desktop {
      font-size: 16px;
    }
  }
}
