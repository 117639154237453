// Grid
//
// Website layout styling
//
// Markup:
// <div class="grid-container">
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
//  <div class="grid-item"></div>
// </div>
//
// Style guide: 2-base.grid

// BASE GRID LAYOUT STYLING
.container {
  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  width: 100%;
  @include tablet {
    padding-left: $container-padding;
    padding-right: $container-padding;
  }
  @include container-min {
    margin: 0 auto;
    max-width: $grid-width + (2 * $container-padding);
  }
}
.grid-item {
  height: 40px;
  background: lightblue;
  grid-column: span 1;
}
.grid-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  width: 100%;

  @include tablet {
    display: grid;
    grid-template-columns: 0 repeat(12, 1fr) 0;
    grid-column-gap: $grid-gap;
    max-width: 1080px;
    padding-left: calc(50px - #{$container-padding});
    padding-right: calc(50px - #{$container-padding});
  }
  @include desktop {
    padding-left: 0;
    padding-right: 0;
  }
  @include desktop-medium {
    max-width: 100%;
    grid-template-columns:
      1fr repeat(12, calc(($grid-width - (11 * $grid-gap)) / 12))
      1fr;
  }
  @include desktop-big {
    max-width: 100%;
    grid-template-columns:
      1fr repeat(12, calc((1620px - (11 * $grid-gap)) / 12))
      1fr;
  }
}

// Custom wide grid
.navigation {
  @include widescreen {
    max-width: 100%;
    grid-template-columns:
      1fr repeat(12, calc($grid-width / 12))
      1fr;
  }
  @include desktop-big {
    max-width: 100%;
    grid-template-columns:
      1fr repeat(12, calc((1620px - (11 * $grid-gap)) / 12))
      1fr;
  }
}

.kss-modifier__example .grid-container {
  @include container-min {
    grid-template-columns: 1fr repeat(12, 1fr) 1fr;
  }
}

.block {
  padding-bottom: 60px;
}

// DISTANCES, POSITIONS ETC.

.padding {
  &--small {
    padding-top: calc(16px / 2);
    padding-bottom: calc(16px / 2);
    @include tablet {
      padding-top: calc(36px / 2);
      padding-bottom: calc(36px / 2);
    }
  }
  &--regular {
    padding-top: calc(26px / 2);
    padding-bottom: calc(26px / 2);
    @include tablet {
      padding-top: calc(50px / 2);
      padding-bottom: calc(50px / 2);
    }
  }
  &--large {
    padding-top: calc(70px / 2);
    padding-bottom: calc(70px / 2);
    @include tablet {
      padding-top: calc(140px / 2);
      padding-bottom: calc(140px / 2);
    }
  }
}

@include landscape-tablet {
  body
    > *:not(.navigation):not(.navigation-menu):not(.footer):not(.block--locations)
    > * {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
}
