.article {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0 $grid-gap;
  @include tablet {
    flex-direction: row;
    display: grid;
    grid-template-columns: 270px 1fr 1fr;
  }
  @include desktop {
    grid-template-columns: repeat(2, 1fr);
  }

  &__image-container {
    width: 100%;
    margin: 0 auto;
    @include tablet {
      margin: 0;
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    @include tablet {
      flex-direction: column;
      flex: 1;
      height: 100%;
      justify-content: center;
      grid-column: auto / span 2;
      padding-top: 20px;
    }
    @include desktop {
      grid-column: 2 / 2;
      padding-top: 30px;
    }
  }
  &__details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-column: 2 / span 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: $grid-gap 0;
  }

  &__title {
    @include limit-lines(2);
  }

  &__summary {
    @include limit-lines(5);
  }

  &__details {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    @include tablet {
      flex-direction: row;
    }
  }

  &__date {
    font-size: 14px;
    margin: 16px 0 0 0;
    @include font-medium;
    text-transform: capitalize;
    @include desktop {
      font-weight: normal;
    }
  }
}
