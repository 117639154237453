// Header Homepage
//
// Markup:
// <div class="content-block content-block--header-homepage grid-container padding--regular show-on-mobile block__background--yellow">
// <div class="content-block__content">
// <p class="header__word--one extra-big--title">Super</p>
// 	<div class="image-container header__big-image">
// 		<picture class="image header__image  fit" data-iesrc="http://localhost:8080/nl/nl/actions/assets/generate-transform?transformId=1" data-alt="Star wars backgrounds 09">
// 			<img src="https://picsum.photos/1920/1200" width="1920" height="1200" style="" loading="lazy" decoding="async">
// 		</picture>
// 	</div>
//   <div class="header__extra-content">
//     <p class="header__word--two extra-big--title">fast</p>
//     <p class="header__word--three extra-big--title">charging</p>
//     <p class="header__word--four extra-big--title">stations</p>
//     <div class="image-container header__small-image">
//       <picture class="image header__image  fit" data-iesrc="http://localhost:8080/nl/nl/actions/assets/generate-transform?transformId=1" data-alt="Star wars backgrounds 09">
//         <img src="https://picsum.photos/252/252" width="252" height="252" style="" loading="lazy" decoding="async">
//       </picture>
//     </div>
//   <div class="header__content">
//     <div class="h2">
//       <p>Experience Electric Freedom with charging that’s as fast as a coffee break.</p>
//     </div>
//   </div>
//  </div>
// </div>
// </div>
//
// Style guide: 3-components.contentblocks.headerHomepage

.content-block--header-homepage {
  overflow: hidden;

  .content-block__content {
    grid-column: 2 / -2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: normal;
    @include desktop {
      grid-column: 3 / -3;
    }
  }
  .header {
    &__word--one,
    &__word--two,
    &__word--three,
    &__word--four {
      z-index: 1;
      @include desktop-only {
        font-size: 100px;
      }
    }
    &__word--one {
      align-self: flex-end;
      width: calc((100% / 12) * 9.5);
      text-align: right;
      transform: translateX(-10%);
      @include desktop {
        width: calc((100% / 9) * 7);
        transform: none;
      }
    }

    &__extra-content {
      display: flex;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      @include tablet {
        display: grid;
        grid-template-columns: minmax(50%, calc(100% - 160px - #{$grid-gap})) 160px;
        grid-template-areas:
          'two image'
          'three image'
          'four image'
          'content content';
        grid-gap: 0 $grid-gap;
      }
      @include desktop-medium {
        grid-template-columns: minmax(50%, calc(100% - 160px - #{$grid-gap})) 270px;
        grid-template-areas:
          'two two'
          'three three'
          'four four'
          'content image';
      }
    }
    &__word--two {
      grid-area: two;
      width: calc((100% / 12) * 10);
      text-align: left;
    }
    &__word--three {
      grid-area: three;
      width: calc((100% / 12) * 10);
      text-align: left;
      transform: translateX(15%);
      @include desktop-medium {
        transform: translateX(1.4em);
      }
    }
    &__word--four {
      grid-area: four;
      width: calc((100% / 12) * 10);
      text-align: left;

      transform: translateX(30%);
      @include desktop-medium {
        transform: translateX(2.8em);
      }
    }

    &__big-image {
      align-self: center;
      justify-content: center;
      width: calc((100% / 12) * 10);
      margin: 20px auto -10px;
      @include tablet {
        width: calc((100% / 12) * 9);
        margin: 10px 0 -36px;
      }
      @include desktop {
        width: calc((100% / 10) * 7);
        margin: 10px 0 -100px;
      }
      @include desktop {
        width: calc((100% / 9) * 7);
        align-self: flex-end;
        margin: 10px 0 -200px;
        transform: none;
      }
      .header__image {
        width: 100%;
      }
    }
    &__small-image {
      grid-area: image;
      width: 80px;
      align-self: flex-end;
      justify-self: flex-end;
      transform: translateY(-200%);
      @include tablet {
        width: 160px;
        transform: none;
      }
      @include desktop-medium {
        margin-bottom: 80px;
      }
    }
    &__content {
      grid-area: content;
      align-self: flex-end;
      width: calc((100% / 12) * 9.5);
      @include tablet {
        margin: 40px 0 0 auto;
      }
      @include desktop-medium {
        align-self: center;
        justify-self: flex-end;
        max-width: 580px;
        margin: 80px 0 0 auto;
      }
    }
    &__content > * {
      width: 100%;
      text-align: center;
      transform: translateX(-10%);
      @include tablet {
        transform: none;
      }
      @include desktop-medium {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
