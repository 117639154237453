.global-block {
  position: relative;
  &.block__background {
    &--yellow {
      background: $brand-color;
    }
    &--white {
      background: white;
    }
    &--gray {
      background: light-grey;
    }
  }

  .dev-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 4px;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: white;
    border-radius: 0 0 3px;
    background: #000;
    text-transform: uppercase;
  }

  &:not(.show-on-mobile) {
    @include mobile-only {
      display: none;
    }
  }

  .global-block__content {
    grid-column: 2 / -2;
  }
}
