// appStoreDownloadVariationTwo
//
// <div class="content-block content-block--app-store-download-variation-two grid-container padding--regular show-on-mobile block__background--yellow">
//    <div class="content-block__content">
//       <h2 class="block__title big--title">Maak snel opladen nog sneller</h2>
//       <div class="block__image-container">
//          <div class="block__first-image-container">
//             <div class="image-container ">
//                <picture class="class="image  image-1" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-09.24.19.png" data-alt="Screenshot 2022 01 05 at 09 24 19">
//                 <img src="https://picsum.photos/1072/576" width="1072" height="576" style="" loading="lazy" decoding="async">
//                </picture>
//             </div>
//          </div>
//          <div class="block__second-image-container desktop">
//             <div class="image-container ">
//                <picture class="image  image-2" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-09.24.19.png" data-alt="Screenshot 2022 01 05 at 09 24 19">
//                   <img src="https://picsum.photos/246/290" width="246" height="290" style="" loading="lazy" decoding="async">
//                </picture>
//             </div>
//          </div>
//       </div>
//       <div class="block__usps-container">
//          <ul class="block__usps">
//             <li class="block__usps__item">
//                <div class="image-container block__usps__image">
//                   <picture class="image  homepage-header-image transparent" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-09.24.19.png" data-alt="Screenshot 2022 01 05 at 09 24 19">
//                      <img src="https://picsum.photos/60/46" width="60" height="46" style="" loading="lazy" decoding="async">
//                   </picture>
//                </div>
//                <p class="block__usps__title h5">Koppel de app aan je bankrekening</p>
//             </li>
//             <li class="block__usps__item">
//                <div class="image-container block__usps__image">
//                   <picture class="image  homepage-header-image transparent" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-09.24.19.png" data-alt="Screenshot 2022 01 05 at 09 24 19">
//                      <img src="https://picsum.photos/60/46" width="60" height="46" style="" loading="lazy" decoding="async">
//                   </picture>
//                </div>
//                <p class="block__usps__title h5">Koppel de app aan je bankrekening</p>
//             </li>
//             <li class="block__usps__item">
//                <div class="image-container block__usps__image">
//                   <picture class="image  homepage-header-image transparent" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-09.24.19.png" data-alt="Screenshot 2022 01 05 at 09 24 19">
//                      <img src="https://picsum.photos/60/46" width="60" height="46" style="" loading="lazy" decoding="async">
//                   </picture>
//                </div>
//                <p class="block__usps__title h5">Koppel de app aan je bankrekening</p>
//             </li>
//             <li class="block__usps__item">
//                <div class="image-container block__usps__image">
//                   <picture class="image  homepage-header-image transparent" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-09.24.19.png" data-alt="Screenshot 2022 01 05 at 09 24 19">
//                      <img src="https://picsum.photos/60/46" width="60" height="46" style="" loading="lazy" decoding="async">
//                   </picture>
//                </div>
//                <p class="block__usps__title h5">Koppel de app aan je bankrekening</p>
//             </li>
//          </ul>
//       </div>
//       <div class="block__second-image-container mobile">
//          <div class="image-container ">
//             <picture class="image  image-2 transparent" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-09.24.19.png" data-alt="Screenshot 2022 01 05 at 09 24 19">
//                <img src="https://picsum.photos/246/290" width="246" height="290" style="" loading="lazy" decoding="async">
//             </picture>
//          </div>
//       </div>
//       <div class="block__appstore appstore appstore--limited">
//          <div class="block__appstore__content">
//             <div class="block__appstore__qr">
//                <a href="https://apps.apple.com/nl/app/fastned/id1485702761" target="_blank">
//                   <div class="image-container ">
//                      <picture class="image  block__appstore__qr__image transparent" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/testing/_100xAUTO_crop_center-center_10_none/Screenshot-2022-01-05-at-09.24.19.png" data-alt="Screenshot 2022 01 05 at 09 24 19">
//                         <img src="https://picsum.photos/108/108" width="108" height="108" style="" loading="lazy" decoding="async">
//                      </picture>
//                   </div>
//                </a>
//             </div>
//             <div class="block__appstore__text">
//                <p class="block__appstore__title">Download the app</p>
//                <div class="redactor block__appstore__redactor">
//                   <p>Gebruik de camera van je telefoon om de gratis Fastned app te downloaden</p>
//                   <p>Beschikbaar voor <a href="https://apps.apple.com/nl/app/fastned/id1485702761" target="_blank" rel="noreferrer noopener">iOS</a> en <a href="https://play.google.com/store/apps/details?id=nl.fastned.my&amp;hl=nl&amp;gl=US" target="_blank" rel="noreferrer noopener">Android</a></p>
//                </div>
//             </div>
//          </div>
//          <div class="block__appstore__link">
//             <a href="https://apps.apple.com/nl/app/fastned/id1485702761" target="_blank" class="button button--secondary">Download the app</a>
//          </div>
//       </div>
//    </div>
//    <label class="dev-label">App store download variation two</label>
// </div>
//
// Style guide: 3-components.contentblocks.appStoreDownloadVariationTwo
.content-block {
  &--app-store-download-variation-two &__content {
    display: flex;
    flex-direction: column;
    @include tablet {
      grid-column: 2 / -2 !important;
    }
    @include desktop {
      grid-column: 3 / -3 !important;
    }
    @include widescreen {
      grid-column: 4 / -4;
    }
  }

  &--app-store-download-variation-two .block {
    &__title {
      align-self: flex-end;
      justify-self: flex-end;
      text-align: center;
      width: 100%;
      @include tablet {
        max-width: 12ch;
        align-self: flex-end;
      }
    }

    &__image-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include tablet {
        flex-direction: row;
        align-items: flex-end;
      }
    }

    &__first-image-container {
      padding: 40px 0 0 0;
      @include tablet {
        padding: 0 10% 0 0;
        width: 70%;
      }
    }

    &__second-image-container {
      width: 30%;
      flex-direction: column;
      align-self: flex-end;
      max-width: 254px;
      @include tablet {
        width: 20%;
        transform: translate(0, 120px);
      }

      &.desktop {
        display: none;
        @include tablet {
          display: block;
        }
      }

      &.mobile {
        display: flex;
        @include tablet {
          display: none;
        }
      }
    }

    &__footnote {
      text-align: center;
    }

    &__usps-container {
      padding: 40px 0 0 0;
      @include tablet {
        padding: 50px 0 0 0;
        width: 70%;
      }
    }

    &__usps {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      @include tablet {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 50px;
        margin-bottom: 25px;
      }
    }

    &__usps__item {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      @include font-light;
      margin: 0 0 1em 0;
      padding-right: 30px;
      word-break: break-word;
      @include tablet {
        width: 100%;
        &:nth-child(2n) {
          transform: translateY(50px);
        }
      }
    }

    &__usps__item > * {
      @include font-light;
      margin: 0;
    }
    &__usps__image {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      flex-shrink: 0;
      @include desktop {
        width: 60px;
        height: 60px;
      }
    }

    &__appstore {
      align-self: flex-end;
      justify-self: flex-end;
      @include desktop {
        width: 50%;
        margin: 70px 0 0 0;
      }
    }
  }
}
