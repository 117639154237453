// Find your EV
//
// Markup:
// <div class="content-block content-block--find-your-ev grid-container padding--regular show-on-mobile block__background--yellow">
//    <div class="content-block__content">
//       <div class="block__content">
//          <div class="search">
//             <div dir="auto" class="v-select vs--single vs--searchable">
//                <div id="vs1__combobox" role="combobox" aria-expanded="false" aria-owns="vs1__listbox" aria-label="Search for option" class="vs__dropdown-toggle">
//                   <div class="vs__selected-options"><span class="vs__selected">Honda E</span> <input aria-autocomplete="list" aria-labelledby="vs1__combobox" aria-controls="vs1__listbox" type="search" autocomplete="off" class="vs__search"></div>
//                   <div class="vs__actions">
//                      <button type="button" title="Clear Selected" aria-label="Clear Selected" class="vs__clear" style="">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
//                            <path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path>
//                         </svg>
//                      </button>
//                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" role="presentation" class="vs__open-indicator">
//                         <path d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"></path>
//                      </svg>
//                      <div class="vs__spinner" style="display: none;">Loading...</div>
//                   </div>
//                </div>
//                <ul id="vs1__listbox" role="listbox" style="display: none; visibility: hidden;"></ul>
//             </div>
//          </div>
//       </div>
//       <div class="block__content">
//          <div class="results--item">
//             <div class="results__header">
//                <h2 class="header__title">Honda E</h2>
//                <a href="https://www.nu.nl" target="_blank" class="header__link button primary">Learn more</a>
//             </div>
//             <div class="restult__details results__connector">
//                <div class="image-container">
//                   <picture class="image"><img src="/assets/icons/ev-connector-ac.svg" alt="AC"></picture>
//                </div>
//                <h3 class="detail__title h2">AC</h3>
//                <p class="detail__type">Connector</p>
//             </div>
//             <div class="restult__details results__charger">
//                <div class="image-container"><img src="/assets/icons/ev-charger.svg" alt="AC/DC"></picture></div>
//                <h3 class="detail__title">AC/DC</h3>
//                <p class="detail__type">Max charge speed</p>
//                <div class="detail__description redactor">
//                   <p>This is the max speed you car lets you fast charge. Even if you use a 350 kW super fast charger.</p>
//                </div>
//             </div>
//          </div>
//       </div>
//    </div>
//    <label class="dev-label">Find your ev</label>
// </div>
//
// Style guide: 3-components.contentblocks.findYourEv

.content-block--find-your-ev {
  .search {
    margin-bottom: 26px;
    @include tablet {
      margin-bottom: 40px;
    }
    @include desktop {
      margin-bottom: 75px;
    }
  }
  .results--default {
    margin-top: 0;
  }
  .results--item {
    margin-top: calc(64px - 26px);
    @include tablet {
      margin-top: calc(106px - 40px);
    }
    @include desktop {
      margin-top: calc(90px - 75px);
    }
  }

  .vs--searchable .vs__dropdown-menu {
    background-image: none;
    &::-webkit-scrollbar {
      width: 14px;
    }
    &::-webkit-scrollbar-track {
      margin: 8px 0;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $dark-yellow;
      border-radius: 20px;
      border: 4px solid $creme;
    }
  }

  // VUE Select
  .vs--searchable {
    .vs__dropdown-toggle {
      border: solid 1px $black;
      padding: 7px 25px;
      border-radius: 50px;
      background: $creme;
      @include tablet {
        border-width: 3px;
        padding: 7px 50px;
      }
    }
    .vs__search,
    .vs__search:focus {
      @include font-regular;
    }

    .vs__selected-options {
      max-width: 100%;
      overflow: hidden;
      &:before {
        content: '';
        width: 1em;
        height: 1em;
        background-image: url('/assets/icons/search.svg');
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .vs__selected,
      input {
        position: relative;
        padding-left: 40px;
      }
      .vs__selected + input {
        display: none;
      }
    }
    .vs__dropdown-menu {
      left: auto;
      right: 0;
      left: 50%;
      transform: translateX(-50%);
      border: solid 1px $black;
      border-radius: 20px;
      padding: 0;
      margin: 8px 0 0 0;
      background: $creme;
      @include tablet {
        border-width: 3px;
      }
      .vs__dropdown-option {
        &:first-of-type {
          margin-top: 60px;
        }
        &:last-of-type {
          margin-bottom: 60px;
        }
      }
      .vs__no-options {
        margin: 0;
        border: none;
      }
    }
    .vs__no-options,
    .vs__dropdown-option {
      width: 100%;
      padding: 4px 50px;
      background: transparent;
      border: none;
      border-top: solid 1px transparent;
      border-bottom: solid 1px transparent;
      transition: background-color 0.2s;
      text-align: left;
      color: $text-color;

      @include tablet {
        border-width: 3px;
      }
      &:hover,
      &:focus,
      &.vs__dropdown-option--highlight {
        background: $yellow;
        border-color: $black;
      }
    }
    .vs__no-options {
      background: $dark-yellow;
      border-color: $black;
    }
    .vs__actions,
    .vs__actions * {
      background: transparent;
      .button__toggle {
        width: 20px;
      }
    }
    .vs__actions {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1em;
        min-width: unset;
        margin: 0 0.5em;
      }
      svg {
        fill: $black;
      }
    }
  }

  .results--default {
    .results__header {
      max-width: 660px;
      margin: 0 auto;
      .redactor {
        text-align: center;
        font-size: inherit;
      }
    }
  }
}
