// Helpers
//
// Dev helper function, to mentain content overview on frontend view
//
// Markup:
// <div><label class="dev-label">usp block</label></div>
//
// Style guide: 1-abstracts.helpers

.dev-label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 4px;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border-radius: 0 0 3px;
  background: #000;
  text-transform: uppercase;
}
