// Common
//
// Style guide: 3-components.common

// Form
//
// Markup:
// <div class="block block--form grid-container padding--regular show-on-mobile">
//     <div class="block__content--header mobile">
//       <h2 class="block__title extra-big--title">Form</h2>
//     </div>
//     <div class="block__content--form">
//         <div class="block__content--header desktop">
//           <h2 class="block__title">Form</h2>
//         </div>
//         <div class="block__text redactor">
//           <p>With a team of in-house experts, we design, develop and operate the station. With a team of in-house experts, we design, develop and operate the station.</p>
//         </div>
//         <span>[...form....]</span>
// 	    </div>
//       <label class="dev-label">Form</label>
//   </div>
//
// Style guide: 3-components.common.form

.block--form {
  .block__content {
    &--header,
    &--form {
      position: relative;
      grid-column: 3 / span 9;
      display: flex;
      flex-direction: column;
      width: calc((100% / 12) * 10);
      margin: 0 auto;
      @include tablet {
        width: 100%;
      }
      @include desktop {
        grid-column: 4 / span 8;
      }
    }
    &--header {
      width: 100%;
      grid-column: 2 / span 10;
      text-align: left;
      &.mobile {
        display: block;
        margin-bottom: 36px;
        @include tablet {
          margin-bottom: 90px;
        }
        @include desktop {
          display: none;
        }
      }
      &.desktop {
        display: none;
        margin-bottom: 0;
        @include desktop {
          display: block;
        }
      }
    }
    &--form {
      max-width: 797px;
      margin: 0 auto;
    }
    &--form .redactor + .freeform {
      margin-top: 40px;
      @include desktop {
        margin-top: 60px;
      }
    }
  }
  .block__title {
    width: 100%;
  }
}
