// Links
//
// Your standard links suitable for clicking.
//
// Markup:
// <a href="#link" class="link {{modifier_class}}">Button</a>
// <button class="link {{modifier_class}}">Button</button>
//
// :hover    - Link Hover state.
// :focus    - Link Focus state
// :actve    - Link Active state
// :disabled - Link Disabled state.
//
// Style guide: 2-base.links

a,
.link {
  @include font-semi-bold;
  display: inline-flex;
  text-decoration: underline;
  color: $text-color;
  transition: all 0.3s;
  border: none;
  background: none;
  &:hover,
  &:focus,
  &:active {
    @include font-bold;
    cursor: pointer;
    outline: none;
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
