// Appstore
//
// Markup:
// <div class="block__appstore {{modifier_class}}">
//   <div class="block__appstore__content">
//     <div class="block__appstore__qr">
//       <a href="https://www.nu.nl" target="_blank">
//         <div class="image-container ">
//           <picture class="image  block__appstore__qr__image transparent fit" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/Icons/_100xAUTO_crop_center-center_10_none/onlink_to_tdpc2k_small.png" data-alt="Onlink to tdpc2k small">
//             <img src="https://source.unsplash.com/random/126x126" width="126" height="126" style="" loading="lazy" decoding="async">
//           </picture>
//         </div>
//       </a>
//     </div>
//     <div class="block__appstore__text">
//       <p class="block__appstore__title">Download the app</p>
//       <div class="redactor"><p>Utilise l'appareil photo de ton téléphone pour obtenir l'application gratuite Fastned.<br><br>Beschikbaar voor iOS en Android.</p></div>
//     </div>
//   </div>
//   <div class="block__appstore__link">
//     <a href="https://www.nu.nl" target="_blank" class="button button--secondary">Download the app</a>
//   </div>
// </div>
//
// .appstore--full  - Render text content on all devices
// .appstore--limited  - Render button on mobile/ tablet and text on desktop
//
// Style guide: 3-components.globals.appstore

@mixin bg-shape {
  padding: 30px 40px 20px 20px;
  clip-path: polygon(0 0, 95% 5%, 100% 100%, 0% 100%);
  background: $dark-yellow;
}

.block__appstore {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 527px;
  @include bg-shape;
  @include desktop {
    padding: 40px 40px 20px 40px;
  }
  @include widescreen {
    padding: 30px 40px 20px 20px;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    @include tablet {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  &__qr {
    display: none;
    @include desktop {
      width: 126px;
      margin: 0 auto 16px;
      margin: 0 36px 0 0;
    }
    &__qr a {
      background: none;
      border: none;
      padding: none;
      transition: all 0.2s;
      border: solid 2px transparent;
    }
    &__qr a:hover,
    &__qr a:focus {
      border: solid 2px $black;
    }
  }
  &__text {
    flex: 1;
    display: flex;
    flex-direction: column;

    font-size: 14px;
  }
  &__text &__title {
    margin: 0;
    @include font-bold;
    margin-bottom: 4px;
    text-transform: uppercase;
  }
  &__text .redactor {
    & > *:not(:last-child) {
      display: none;
      @include desktop {
        display: block;
      }
    }
  }
  &__link {
    display: none;
    @include desktop {
      display: none;
    }
  }
  &__link a {
    margin: 0;
  }

  // Custom styles
  // Full content
  &.appstore--full &__qr {
    display: none;
    @include desktop-medium {
      display: block;
    }
  }

  // Mobile/tablet Button
  &.appstore--limited {
    padding: 0;
    clip-path: none;
    background: none;
    @include tablet {
      @include bg-shape;
    }
  }
  &.appstore--limited &__content {
    display: none;
    padding: 0;
    background: none;
    @include tablet {
      display: flex;
    }
  }
  &.appstore--limited &__link {
    display: block;
    @include tablet {
      display: none;
    }
  }
  &.appstore--limited &__qr {
    display: none;
    @include desktop-medium {
      display: block;
    }
  }
}
