.block--article-info {
  padding: 0;

  .block {
    &__content {
      grid-column: 2 / -2;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: $grid-gap $grid-gap;
      justify-content: center;

      .image-container {
        grid-column: 1 / -1;
        @include desktop {
          grid-column: 3 / -3;
          text-align: center;
        }
      }
    }

    &__date {
      grid-column: 2 / -2;
      @include desktop {
        display: none;
      }
    }

    &__title {
      grid-column: 2 / -2;
      @include desktop {
        grid-column: 1 / -1;
        text-align: center;
      }
    }

    &__intro {
      grid-column: 2 / -2;
      @include desktop {
        text-align: center;
      }
    }
  }
}
