.langue-dropdowns__container {
  display: flex;
  flex-direction: column;
  gap: $grid-gap;
  margin: 0 auto;
  align-items: center;
  @include desktop {
    flex-direction: row;
    justify-content: space-between;
  }
  .dropdown__group {
    display: block;
    @include desktop {
      max-width: 325px;
    }
  }
}
//   // Vue-Select

.dropdown__group {
  min-width: 310px;
  font-size: 20px;

  .v-select {
    width: 100%;
  }

  .vs__dropdown-menu {
    // Scrollbar
    &::-webkit-scrollbar {
      width: 14px;
    }
    &::-webkit-scrollbar-track {
      margin: 8px 0;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $dark-yellow;
      border-radius: 20px;
      border: 4px solid $creme;
    }
  }

  .v-select,
  .v-select.vs--open,
  .v-select.vs--disabled {
    .vs__dropdown-option,
    .vs__selected {
      display: inline-flex;
      align-items: center;
      color: inherit;
    }

    .vs__dropdown-toggle {
      display: inline-flex;
      width: 100%;
    }
    .vs__selected-options {
      flex-basis: unset;
    }

    .vs__selected,
    .vs__search {
      white-space: nowrap;
      min-height: 32px;
      font-size: inherit;
      @include font-regular;
    }
    .vs__selected + .vs__search {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }

    .vs__search {
      display: block;
      position: relative;
      width: auto;
      margin: 0;
      padding: 0;
    }
    .vs__search,
    .vs__dropdown-option {
      background-color: inherit;
    }
    &.vs--disabled {
      opacity: 0.7;
      background: none;
    }
    &.vs--open {
      .vs__selected-options {
        .vs__selected {
          position: relative;
          opacity: 1;
        }
      }
    }
  }

  .vs__dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 16px;
    border-radius: 50px;
    border: 2px solid $black;
    background: $white;
  }
  .vs__search {
    display: none;
  }

  .vs__dropdown-toggle {
    display: flex;
    flex-direction: row;
  }
  .vs__dropdown-option,
  .vs__selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .option__text {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $black;
    font-size: inherit;
    @include font-regular;
    flex: 1;
  }
  .flag {
    width: 40px;
    height: 30px;
    margin-right: 16px;
  }
  .button__toggle {
    margin: 0;
    padding-left: 16px;
    width: 36px;
  }

  // List items
  .vs__dropdown-menu {
    background: $white;
    color: $black;
    padding: 20px;
    border-radius: 12px;
    border: 2px solid $black;
    margin-top: 4px;
    @include font-regular;
    font-size: inherit;
    @include tablet {
      padding: 30px;
    }
  }
  .vs__dropdown-option {
    justify-content: flex-start;
    padding: 8px 4px;
    border-bottom: 1px solid $black;
    background: none;
    color: $black;
    @include tablet {
      padding: 16px 8px;
    }
    &:first-child {
      margin-top: -8px;
    }
    &:last-child {
      margin-bottom: -8px;
      border-bottom: none;
    }
    &.vs__dropdown-option--highlight {
      opacity: 0.7;
    }
    &.vs__dropdown-option--selected {
      opacity: 1;
    }
  }

  // Open
  .vs--open,
  .vs--single.vs--open,
  .vs--single.vs--loading {
    .vs__dropdown-toggle {
      border-radius: 50px;
      border-bottom: 2px solid $black;
    }
    .vs__selected {
      position: relative;
      opacity: 1;
    }
  }
}
