// multimedia
//
// Style guide: 3-components.contentblocks.multimedia
.content-block {
  &--multimedia {
  }
  &--multimedia &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &--multimedia .media__image,
  &--multimedia .media__image picture {
    width: 100%;
    height: auto;
  }
  &--multimedia .media__footnote {
    width: 100%;
    margin: 20px auto;
    text-align: center;
    @include tablet {
      max-width: 80%;
    }
  }
}
