// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
  position: relative;
  z-index: 9;
  width: 100%;
  background: $footer-background;
  padding: 24px 24px;
  margin-top: 40px;
  min-height: $footer-height;
  @include tablet-only {
    min-height: $footer-height-tablet;
  }
  @include desktop {
    margin-top: 80px;
    min-height: $footer-height;
  }

  &__container {
    grid-column: 2 / -2;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__list {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0 0 0;
    @include tablet {
      flex: unset;
      width: auto;
      padding: 0;
      justify-content: start;
      margin-right: $grid-gap;
    }
  }

  &__link {
    margin: 0 15px 0 0;
    text-decoration: none;
    font-weight: 400;
    &:last-child {
      margin-right: 0;
    }
    @include desktop {
      font-size: 20px;
    }
    @include desktop-medium {
      margin: 0 72px 0 0;
    }
    @include on-event {
      text-decoration: underline;
    }
  }

  // socials styling footer below
  &__socials {
    width: max-content;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    @include tablet {
      justify-content: end;
    }
    .global-block__social {
      margin: 0;
    }
  }
}
