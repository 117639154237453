/*
Colors

Style guide: 1-abstracts.colors
*/

/*
Base Colors

Colors:
$white: #ffffff - White
$yellow: #FDDC2E - Yellow
$black: #000000 - Black

Style guide: 1-abstracts.colors.base
*/
$white: #ffffff;
$yellow: #fddc2e;
$black: #000000;

/*
Support Colors

Colors:
$creme: #FBF1BF - Creme
$dark-yellow: #E5CA3A - Dark Yellow
$dark-border: #4F4D4C - Dark border

Style guide: 1-abstracts.colors.support
*/
$creme: #fbf1bf;
$dark-yellow: #e5ca3a;
$dark-border: #4f4d4c;

/// Button colors
/// @type Color
$btn-bg-first: $yellow;
$btn-text-first: $white;
$btn-bg-first-hover: $dark-yellow;
$btn-bg-second: $black;
$btn-text-second: $white;
$btn-bg-second-hover: $dark-yellow;

/// Main brand color
/// @type Color
$brand-color: $yellow !default;

/// Text colors
/// @type Color
$text-color: $black;

// Main Assets color
/// @type Color
$assets-color: $creme !default;

// TIM
$light-yellow: rgb(251, 241, 191);

// Color frame section

$header-background: $brand-color;
$footer-background: $light-yellow;

$primary-background: $brand-color;
