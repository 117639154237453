/*
Breakpoints

Device sizes:
- $mobile: 412px;
- $tablet: 768px;
- $tablet-landscape: 1024px;
- $desktop: 1024px;
- $widescreen: 1440px;
- $desktop-big: 1800px;

Style guide: 1-abstracts.breakpoints
*/

$mobile: 412px;
$tablet: 768px;
$tablet-landscape: 1024px;
$desktop: 1024px;
$desktop-medium: 1140px;
$widescreen: 1440px;
$desktop-big: 1800px;

/*
Base

Base screensize mixins:
- container-min
- tablet - tablet
- tablet-landscape
- desktop
- widescreen
- desktop-big

Style guide: 1-abstracts.breakpoints.mixins
*/

@mixin container-min {
  @media only screen and  (min-width: #{$grid-width + $grid-gap * 2}) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (min-width: #{$tablet}) {
    @content;
  }
}
@mixin tablet-landscape {
  @media only screen and (min-width: #{$tablet-landscape}) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: #{$desktop}) {
    @content;
  }
}
@mixin desktop-medium {
  @media only screen and (min-width: #{$desktop-medium}) {
    @content;
  }
}
@mixin widescreen {
  @media only screen and (min-width: #{$widescreen}) {
    @content;
  }
}
@mixin desktop-big {
  @media only screen and (min-width: #{$desktop-big}) {
    @content;
  }
}

/*
Device specific

Device specific mixins:
- mobile-only
- tablet-only
- desktop-only
- widescreen-only
- desktop-big-only

Style guide: 1-abstracts.breakpoints.mixins
*/

@mixin mobile-only {
  @media only screen and (max-width: #{$tablet - 1}) {
    @content;
  }
}
@mixin tablet-only {
  @media only screen and (min-width: #{$tablet}) and (max-width: #{$desktop - 1}) {
    @content;
  }
}
@mixin desktop-only {
  @media only screen and (min-width: #{$desktop}) and (max-width: #{$desktop-medium - 1}) {
    @content;
  }
}
@mixin desktop-medium-only {
  @media only screen and (min-width: #{$desktop-medium}) and (max-width: #{$widescreen - 1}) {
    @content;
  }
}
@mixin widescreen-only {
  @media only screen and (min-width: #{$widescreen}) and (max-width: #{$desktop-big - 1}) {
    @content;
  }
}
@mixin desktop-big-only {
  @media only screen and (min-width: #{$desktop-big}) {
    @content;
  }
}

/// solution for interpolation of mixin names (which is not allowed)
/// @param {String} $breakponit
$media-query-list: mobile, tablet, tablet-landscape, desktop, widescreen,
  desktop-big;

@mixin media-query($breakpoint) {
  @if not index($media-query-list, $breakpoint) {
    @error "#{$breakpoint} is not a valid breakpoint.";
  } @else if $breakpoint== 'mobile' {
    @include mobile {
      @content;
    }
  } @else if $breakpoint== 'tablet' {
    @include tablet {
      @content;
    }
  } @else if $breakpoint== 'tablet-landscape' {
    @include tablet-landscape {
      @content;
    }
  } @else if $breakpoint== 'desktop' {
    @include desktop {
      @content;
    }
  } @else if $breakpoint== 'widescreen' {
    @include widescreen {
      @content;
    }
  } @else if $breakpoint== 'desktop-big' {
    @include desktop-big {
      @content;
    }
  }
}

/*
Custom

Custom size mixins:
- mq($width1, $width2: 0)

Style guide: 1-abstracts.breakpoints.mixins
*/
@mixin mq($width1, $width2: 0) {
  @if $width2>0 {
    @media only screen and (min-width: #{$width1}) and (max-width: #{$width2}) {
      @content;
    }
  } @else {
    @media only screen and (min-width: #{$width1}) {
      @content;
    }
  }
}

@mixin landscape-mobile {
  @media only screen and (orientation:landscape) and (max-width: #{$tablet - 1}) {
    @content;
  }
}
@mixin landscape-tablet {
  @media only screen and (orientation:landscape) and (max-width: #{$desktop-medium - 1}) {
    @content;
  }
}
@mixin landscape-all {
  @media only screen and (orientation: landscape) {
    @content;
  }
}
@mixin until-desktop {
  @media only screen and (max-width: #{$desktop - 1}) {
    @content;
  }
}
