// Contentblocks
//
// Contentblocks
//
// Style guide: 3-components.contentblocks
.content-block {
  position: relative;
  background: $brand-color;
  &.block__background {
    &--black {
      background: $dark-border;
    }
    &--white {
      background: $white;
    }
    &--creme {
      background: $creme;
    }
    &--yellow {
      background: $brand-color;
    }
    &--dark-yellow {
      background: $assets-color;
    }
  }

  &:not(.show-on-mobile) {
    @include mobile-only {
      display: none;
    }
  }

  .content-block__content {
    width: 100%;
    @include tablet {
      grid-column: 2 / -2;
    }
    @include desktop {
      grid-column: 4 / -4;
    }
  }
}
