/*colors*/
#cookieinfo-banner {
  font-size: 16px;
  background-color: #fbf1bf; /*background-color of cookiebanner*/
}

/* cookie test message*/
#cookieinfo-banner > div:not(#c-left):not(#c-right):not(.c-clear) {
  background: red;
  font-size: 16px !important;
}

#cookieinfo-banner,
#cookieinfo-banner p {
  color: #000000; /*text-color of cookiebanner*/
}
#cookieinfo-banner a {
  color: #000000; /*link-color of links in text*/
}
#cookieinfo-banner a:hover {
  color: #cc612d; /*link-color on mouse-over links in text*/
}
#cookieinfo-banner a.c-button {
  color: #fbf1bf; /*text-color of accept button*/
  background-color: #000000; /*background-color of accept button*/
  border-color: #000000; /*text-color of manage cookies button*/
}
#cookieinfo-banner a.c-button:hover {
  background-color: #000000; /*background-color on mouse-over of accept button*/
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.6);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.6);
}
#cookieinfo-banner a.c-link {
  color: #000000; /*text-color of manage cookies button*/
  border-color: #fbf1bf; /*text-color of manage cookies button*/
}
#cookieinfo-banner a.c-link:hover {
  color: #000000; /*text-color on mouse-over of manage cookies button*/
  border-color: #fbf1bf; /*border-color on mouse-over of manage cookies button*/
  text-decoration: underline; /* underline  on mouse-over of manage cookies button */
}
#cookieinfo-banner .c-label .checkmark {
  border-color: #000000; /*border-color checkbox*/
}
#cookieinfo-banner input:checked ~ .checkmark {
  background-color: #000000; /*background-color of checked checkbox*/
  border-color: #000000; /*border-color of checked checkbox*/
}
#cookieinfo-banner input:checked[disabled] ~ .checkmark {
  background-color: #d8d8d8; /*background-color of disabled checkbox*/
  border-color: #d8d8d8; /*border-color of disabled checkbox*/
}
#cookieinfo-banner .c-label .checkmark:after,
#cookieinfo-banner .c-label input:checked[disabled] ~ .checkmark:after {
  border-color: #fbf1bf; /*color of check in checkbox*/
}
#cookieinfo-banner .c-label input:checked[disabled] ~ .checkmark:after {
  border-color: #969696; /*color of check in disabled checkbox*/
}
/*end colors*/

#cookieinfo-banner {
  z-index: 2147483645;
  border-radius: 3px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 900px;
  max-width: 80%;
  max-height: 95%;
  padding: 40px 50px 50px 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 70px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: auto;
  display: none;
}

#cookieinfo-banner label {
  font-weight: bold;
  cursor: pointer;
}

#cookieinfo-banner input {
  cursor: pointer;
  margin: 0 5px 5px 0;
}

#cookieinfo-banner .c-clear {
  clear: both;
}

#c-right {
  margin-top: 20px;
  padding-right: 20px;
  float: right;
}

#c-left {
  display: flex;
}

#c-content {
  flex: 1;
}
#cookieinfo-banner .c-logo {
  width: 100px;
  padding-right: 20px;
}
#cookieinfo-banner .c-logo a,
#cookieinfo-banner .c-logo img {
  width: 100%;
}

#cookieinfo-banner div.c-header {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: -10px;
}

#cookieinfo-banner div.c-message {
  font-size: 1em;
  margin: 0;
  padding-bottom: 2em;
}
#cookieinfo-banner div.c-message.c-step1 {
  padding-bottom: 0;
}

#cookieinfo-banner dl {
  margin: 0;
  line-height: 1em;
}
#cookieinfo-banner dt {
  padding-bottom: 0.4em;
}
#cookieinfo-banner .c-message dl + br,
#cookieinfo-banner .c-message dl + br + br {
  display: none;
}

#cookieinfo-banner dd {
  font-size: 0.9em;
  margin: 0;
  padding: 0.4em 0 0 2.2em;
}

#cookieinfo-banner a.c-button,
#cookieinfo-banner a.c-link {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline;
  text-decoration: none;
  padding: 12px 30px;
  margin: 0px 0px 0px 0px;
  font-weight: bold;
  text-align: center;
  width: auto;
  float: left;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}

#cookieinfo-banner .c-step2,
#cookieinfo-banner a.c-step2 {
  display: none;
}

#cookieinfo-banner .c-label {
  display: block;
  margin: 0px;
  position: relative;
  padding: 0 2em 1em 2em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#cookieinfo-banner p.c-labeltxt {
  font-size: 0.9em;
  margin: 0;
  font-weight: normal;
  padding-top: 3px;
}

#cookieinfo-banner .c-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

#cookieinfo-banner .c-label .checkmark {
  position: absolute;
  top: 3px;
  left: 0px;
  height: 1.1em;
  width: 1.1em;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
}

#cookieinfo-banner input:checked ~ .checkmark {
  border-width: 1px;
  border-style: solid;
}

#cookieinfo-banner .disabled {
  cursor: default;
}

#cookieinfo-banner .c-label input:checked ~ .checkmark:after {
  display: block;
}

#cookieinfo-banner .c-label .checkmark:after,
#cookieinfo-banner .c-label input:checked[disabled] ~ .checkmark:after {
  content: '';
  position: absolute;
  left: 0.33em;
  top: 0.05em;
  width: 0.3em;
  height: 0.6em;
  border-width: 0 3px 3px 0;
  border-style: solid;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  transform: rotate(35deg);
  border-radius: 2px;
}

@media screen and (max-width: 800px) {
  #cookieinfo-banner {
    padding: 15px 20px 30px 20px;
    height: auto;
  }

  #cookieinfo-banner .c-logo {
    display: none;
  }
  #c-right {
    width: 100%;
    padding: 0;
  }
  #cookieinfo-banner a.c-link {
    margin: 0;
    padding: 6px 0px 10px;
    width: 100%;
    text-align: center;
  }
  #cookieinfo-banner a.c-button {
    width: 100%;
    margin: 5px 0 0 0;
  }
}
@media screen and (min-width: 1024px) {
  #cookieinfo-banner div.c-message.c-step1 {
    padding-bottom: 2em;
  }
}

body #CookiebotWidget .CookiebotWidget-header {
  border-color: #000000;
}
body #CookiebotWidget #CookiebotWidget-widgetContent {
  background: #fbf1bf;
}
body
  #CookiebotWidget
  .CookiebotWidget-body
  .CookiebotWidget-consents-list
  li
  svg,
body #CookiebotWidget .CookiebotWidget-consent-details button {
  color: #000000;
  fill: #000000 !important;
}
body
  #CookiebotWidget
  .CookiebotWidget-consent-details
  .CookiebotWidget-consent-details-box {
  background: white;
}
body #CookiebotWidget #CookiebotWidget-buttons {
  border-color: #000000;
}
body #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
  background: #fbf1bf;
  border: 2px solid #000000;
  color: #000000;
}
body #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
  background: #000000;
  border: 2px solid #000000;
  color: #fbf1bf;
}
