.navigation {
  &__button {
    @include font-semi-bold;
    line-height: 0.5em;
    position: relative;
    width: max-content;
    font-weight: 400;
    height: 34px;
    overflow: hidden;
    background: none;
    border: none;
    font-size: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    color: black !important;
    @include desktop {
      font-size: 20px;
    }
    &:focus,
    &:hover {
      cursor: pointer;
    }
  }

  .hamburger {
    position: relative;
    width: 32px;
    height: 32px;
    margin: 0 0 0 10px;

    &__bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 5px;
      background: $black;
      border-radius: 2px;
      transition: all 0.3s ease-in;

      &:nth-of-type(2) {
        top: 50%;
        transform: translateY(-100%);
      }
      &:nth-of-type(3) {
        top: 50%;
        transform: translateY(-100%);
      }
      &:nth-of-type(4) {
        top: auto;
        bottom: 0;
        transform: translateY(-100%);
      }
    }

    &.open {
      .hamburger__bar {
        &:nth-of-type(1),
        &:nth-of-type(4) {
          display: none;
        }
        &:nth-of-type(2) {
          transform: translateY(-100%) rotate(45deg);
        }
        &:nth-of-type(3) {
          transform: translateY(-100%) rotate(-45deg);
        }
      }
    }
  }
}
