.page--locations {
  .footer {
    margin-top: 0;
  }
}

.block--locations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 0;
  flex: 1;
  iframe {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    flex: 1;
  }
}
