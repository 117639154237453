.related-article {
  position: relative;
  text-decoration: none;

  .image-container {
    width: 160px;
    @include tablet {
      width: 103px;
    }
    @include tablet {
      width: 100%;
    }

    .image img {
      object-fit: cover !important;
    }
  }

  &__content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    bottom: 0;
    @include tablet {
      height: 100%;
    }
    @include desktop {
      position: absolute;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 10%,
        rgba(0, 0, 0, 0) 40%
      );
      justify-content: flex-end;
      padding: 14px;
    }
  }

  &__title {
    margin: 0;
    @include desktop {
      color: $white;
    }
  }

  &__date {
    display: none;
    @include desktop {
      display: block;
      font-size: 20px;
      font-weight: 300;
      color: $white;
      margin: 0;
      padding: 0;
    }
  }
}
