// HeaderPrices
//
// Markup:
// <div class="content-block content-block--header-prices grid-container padding--regular show-on-mobile block__background--yellow">
//   <div class="content-block__content">
//   <div class="content-block__inner">
//     <div class="header-prices__column">
//       <div class="header-prices__column__content header-prices__header">
//         <h2 class="header-prices__header__title h1">Pay as you go </h2>
//         <div class="header-prices__header__price redactor h4">
//           <svg class="image-shape" viewBox="0 0 519 276" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
//             <path d="M0 0L518.17 12L506.655 262L9.59575 276L0 0Z" fill="#FBF1BF"></path>
//           </svg>
//           <h1>€ 0.69</h1>
//           <h3>per kWh</h3>
//         </div>
//       </div>
//       <div class="header-prices__column__content header-prices__text">
//         <h3 class="header-prices__text__title h2">Charge cards. Bank cards. Birthday cards. We love them all.</h3>
//         <div class="header-prices__text__links">
//         <a href="http://localhost:8080/nl/nl/cookies" target="" class="link--secondary">Terms &amp; Conditions</a>
//         <a href="http://localhost:8080/nl/nl/cookies" target="" class="link--secondary">Privacy Policy</a>
//       </div>
//     </div>
//     <div class="header-prices__column__content header-prices__extra">
//       <div class="image-container header-prices__extra__image">
//         <picture class="image " data-iesrc="https://picsum.photos/390/124" data-alt="Star wars backgrounds 09">
//           <img src="https://picsum.photos/390/124" width="390" height="124" style="" loading="lazy" decoding="async">
//         </picture>
//       </div>
//       <div class="redactor header-prices__extra__text">
//         <p>Dein Karten-Anbieter könnte Extra-Gebühren erheben. Schau am besten auf seiner Webseite nach.</p>
//       </div>
//     </div>
//   </div>
//   <div class="header-prices__column">
//     <div class="header-prices__column__content header-prices__header">
//       <h2 class="header-prices__header__title h1">Save with a subscription </h2>
//       <div class="header-prices__header__price redactor h4">
//         <svg class="image-shape" viewBox="0 0 519 276" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
//           <path d="M0 0L518.17 12L506.655 262L9.59575 276L0 0Z" fill="#FBF1BF"></path>
//         </svg>
//         <p><strong>Netherlands</strong> € 0.69 per kWh<br><strong>Germany</strong> € 0.69 per kWh<br><strong>Belgium</strong> € 0.69 per kWh<br><strong>France</strong> € 0.59 per kWh<br><strong>Switzerland</strong> Fr. 0.69 per kWh<br><strong>UK</strong> £ 0.49 per kWh</p>
//       </div>
//       <div class="header-prices__header__footnote h5">
//         <p>€ 11.99 per month</p>
//       </div>
//     </div>
//     <div class="header-prices__column__content header-prices__text">
//       <h3 class="header-prices__text__title h2">Perfect for busy drivers who like our app.</h3>
//       <div class="header-prices__text__links"></div>
//     </div>
//   </div>
// </div>
// </div>
// <label class="dev-label">Header prices</label>
// </div>
//
// Style guide: 3-components.contentblocks.headerPrices

.content-block {
  &--header-prices &__content {
    grid-column: 2 / -2;
    word-break: normal;
    @include desktop {
      grid-column: 3 / -3;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: $grid-gap;
    }
    @include desktop {
      gap: #{$grid-gap * 2} #{$grid-gap * 4};
      grid-template-columns: repeat(10, 1fr);
    }
  }
}

.header-prices {
  &__column {
    @include tablet {
      width: calc(100% - #{$grid-gap});
      max-width: 526px;
    }
  }
  &__column:nth-of-type(1n + 2) {
    margin-top: 70px;
  }

  &__header__footnote {
    padding: 0 1.5em;
  }

  // Column odd
  &__column:nth-of-type(odd) {
    grid-column: 1 / span 6;
    @include tablet {
      margin-right: auto;
    }
    @include desktop {
      grid-column: 1 / span 5;
    }
    .header-prices__header,
    .header-prices__text,
    .header-prices__text__links {
      justify-content: flex-start;
      text-align: left;
      @include font-light;
    }
    .header-prices__header__footnote {
      text-align: left;
    }
  }

  // Column even
  &__column:nth-of-type(even) {
    grid-column: -7 / span 6;
    @include tablet {
      margin-left: auto;
    }
    @include desktop {
      grid-column: -6 / span 5;
    }
    .header-prices__header {
      justify-content: flex-end;
      text-align: right;
      @include font-light;
    }
    .header-prices__text,
    .header-prices__text__links {
      justify-content: flex-start;
      text-align: left;
    }
    .header-prices__header__footnote {
      text-align: right;
    }
  }

  // Header content
  &__header {
    margin-bottom: 40px;
    @include tablet {
      margin-bottom: 50px;
    }
    @include desktop {
      margin-bottom: 80px;
    }
  }
  &__header__title {
    margin-bottom: 32px;
    @include mobile-only {
      font-size: 40px;
    }
    @include tablet {
      margin-bottom: 18px;
    }
    @include desktop {
      text-align: center;
      margin-bottom: 68px;
    }
  }
  &__header__price {
    position: relative;
    display: block;
    padding: 40px;
    text-align: left;
  }
  &__header__price .extra-big--title,
  &__header__price .big--title,
  &__header__price .semi-big--title,
  &__header__price h1,
  &__header__price h2 {
    @include font-medium;
    font-size: 50px;
    line-height: 1;
    @include tablet {
      font-size: 60px;
    }
    @include desktop {
      font-size: 70px;
    }
  }
  &__header__price h3 {
    @include font-medium;
    font-size: 30px;
    line-height: 1;
    @include tablet {
      font-size: 40px;
    }
    @include desktop {
      font-size: 50px;
    }
  }
  &__header__price h1,
  &__header__price h2,
  &__header__price h3,
  &__header__price h4,
  &__header__price h5,
  &__header__price h6 {
    text-align: center;
  }

  &__header__price > *:not(svg) {
    position: relative;
    display: block;
    z-index: 1;
  }
  &__header__price svg {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
  }

  // Text content
  &__text {
    margin-bottom: 40px;
    @include tablet {
      margin-bottom: 50px;
    }
    @include desktop {
      margin-bottom: 80px;
    }
  }
  &__text__title {
    margin-bottom: 1.3em;
  }
  &__text__redactor {
    @include font-light;
  }
  &__text__redactor p,
  &__text__redactor ul,
  &__text__redactor ol,
  &__text__redactor li {
    @include font-light;
  }
  &__text__links {
    display: flex;
    flex-wrap: wrap;
    @include font-light;
    color: #9e8500;
  }
  &__text__links a {
    margin-right: 0.5em;
  }
  &__text__links a:not(:hover),
  &__text__links a:not(:focus) {
    @include font-light;
  }

  // Extra content
  &__extra {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 auto;
  }
  &__extra &__extra__image {
    overflow: hidden;
    margin: 0 0 30px;
    max-width: 390px;
    @include tablet {
      margin: 0 auto 30px 0;
      width: 80%;
    }
  }
  &__extra &__extra__text {
    width: 100%;
    margin: 0 auto 30px;
    @include tablet {
      margin: 0 0 0 auto;
    }
  }
}
