// textWithImageMask
//
// Style guide: 3-components.contentblocks.textWithImageMask
.content-block--text-with-image-mask {
  .text-with-image-mask-image {
    padding: 30px 0 50px 0;
    @include tablet {
      padding: 20px 0 40px 0;
    }
    @include desktop {
      padding: 60px 0 100px 0;
    }
  }

  .image-footnote-container {
    position: relative;
    .image-container {
      width: 100%;
    }
    .image-container picture {
      width: 100%;
    }
  }

  .footnotes {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(6, 1fr);
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;

    .footnote {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      display: none;
      @include tablet {
        display: flex;
      }

      &-1 {
        grid-column: 6 / span 2;
        grid-row: 1 / 1;
      }

      &-2 {
        grid-column: 1 / span 2;
        grid-row: 2 / 2;
      }

      &-3 {
        grid-column: 8 / span 2;
        grid-row: 4 / 4;
      }

      &-4 {
        grid-column: 2 / span 2;
        grid-row: 5 / 5;
        align-self: flex-end;
      }
    }
  }

  .redactor {
    @include tablet {
      width: 60%;
    }
    @include desktop {
      width: 80%;
    }

    h2 {
      margin: 0 0 30px 0;
    }
  }
}
