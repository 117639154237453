/*
Animations

Style guide: 1-abstracts.animations
*/

// Animations
//
// Your standard block animations.
//
// Markup:
// <div class="animation-example {{modifier_class}}">Button</div>
//
// .shake-right - Shake-right animation
// .shake-left   - Shake-left animation
// .spin        - Spin animation
//
// Style guide: 1-abstracts.animations

.animation-example {
  display: block;
  width: 80px;
  height: 80px;
  background: $brand-color;
  animation-delay: 2s;
}

.shake-right {
  animation: shake-right 1s ease-in-out;
}
.shake-left {
  animation: shake-left 1s ease-in-out;
}
.spin {
  animation: spin 1s ease-in-out;
}
.fade {
  animation: fade 1s ease-in-out;
}

@keyframes shake-right {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }

  25%,
  75% {
    transform: translateX(5px);
  }
}

@keyframes shake-left {
  0%,
  50%,
  100% {
    transform: translateX(0);
  }

  25%,
  75% {
    transform: translateX(-5px);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes menu-slide {
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: calc(100vh - #{$navigation-height});
    opacity: 1;
  }
}

// Vue transitions
.fade-enter-active {
  animation: menu-slide 0.3s;
}
.fade-leave-active {
  animation: menu-slide 0.3s reverse;
}
