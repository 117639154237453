.nav-paginate {
  margin: 16px 0 0;
  * {
    color: $text-color
  }
  .nav-paginate-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    font-size: 18px;
    line-height: 1.1;
    justify-content: center;
    list-style: none;
    .nav-paginate-item{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  button, 
  .is-current-page {
    padding: 0 12px;
    height: 32px;
    border: none;
    background: none;
    cursor: pointer;
    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .is-current-page{
    background: red;
  }
}