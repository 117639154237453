// imageTwoThirds
//
// Style guide: 3-components.contentblocks.imageTwoThirds

.content-block {
  &--image-two-thirds &__content {
    display: flex;
    flex-direction: column;
    &.left {
      .title__container {
        justify-content: flex-start;
        text-align: left;
        margin-right: auto;
      }
      .footnote__container {
        justify-content: flex-end;
        text-align: left;
        margin-right: auto;
      }
    }

    &.center {
      .title__container,
      .footnote__container {
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.right {
      .title__container {
        justify-content: flex-end;
        text-align: right;
        margin-left: auto;
      }
      .footnote__container {
        justify-content: flex-start;
        text-align: left;
        margin-left: auto;
      }
    }
  }

  &--image-two-thirds .title__container,
  &--image-two-thirds .footnote__container {
    display: flex;
    width: 85%;
  }

  &--image-two-thirds .image-twothirds {
    margin: 20px 0;
    @include tablet {
      margin: 30px 0;
    }
    @include desktop {
      margin: 50px 0 70px;
    }
  }
}
