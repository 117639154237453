/*
Mixins

@mixin on-event()

Mixin, to include styling hover, focus, active

- $self - Whether or not to include current selector (default: false)

/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts

Style guide: 1-abstracts.mixins
*/
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin when-after($context) {
  #{$context} + & {
    @content;
  }
}

@mixin basic-reset {
  list-style: none;
  background: none;
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
}

// mixin limit-lines(@amount)
//
// Applies line cut-off with ellipsis after a specified amount
//
// @amount = 3 - lines to disbplay, before cut-off with '...' (default: 3)
//
// Style guide: 1-abstracts.mixins.example
@mixin limit-lines($amount: 3) {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $amount;
  -webkit-box-orient: vertical;
}

// mixin flex-center
//
// Applies the following effects on the selected item.
// - display: flex;
// -align-items: center;
// -justify-content: center;
//
// Style guide: 1-abstracts.mixins.example

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
