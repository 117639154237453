// multiColumnUsps
//
// Markup:
//<div class="content-block content-block--multi-column-usps grid-container padding--regular show-on-mobile block__background--yellow">
// <div class="content-block__content">
//  <div class="columns">
//    <div class="column col-3  block--with-content">
//	    <div class="image-container">
//        <picture class="image fit">
//          <img src="https://source.unsplash.com/random/500x500" width="500" height="500" style="" loading="lazy" decoding="async">
//        </picture>
//      </div>
//      <div class="block__text">
//        <div class="redactor">Starting from €0.45 per kWh</div>
//      </div>
//    </div>
//    <div class="column col-3  block--with-content">
//	    <div class="image-container">
//        <picture class="image fit">
//          <img src="https://source.unsplash.com/random/500x500" width="500" height="500" style="" loading="lazy" decoding="async">
//        </picture>
//      </div>
//      <div class="block__text">
//        <div class="redactor">Starting from €0.45 per kWh</div>
//      </div>
//    </div>
//    <div class="column col-3  block--with-content">
//	    <div class="image-container">
//        <picture class="image fit">
//          <img src="https://source.unsplash.com/random/500x500" width="500" height="500" style="" loading="lazy" decoding="async">
//        </picture>
//      </div>
//      <div class="block__text">
//        <div class="redactor">Starting from €0.45 per kWh</div>
//      </div>
//    </div>
//  </div>
//  <label class="dev-label">Multi column usps</label>
// </div>
// </div>
//
// Style guide: 3-components.contentblocks.multiColumnUsps

.content-block--multi-column-usps {
  .columns {
    display: flex;
    flex-direction: column;
    gap: 36px;
    @include tablet {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: $grid-gap;
      text-align: center;
      justify-content: space-between;
    }
  }

  .column {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    @include mobile-only {
      align-items: center;
      .block--without-content {
        display: none;
      }
      @for $i from 1 through 10 {
        &.order-#{$i} {
          order: #{$i};
        }
      }
    }
    @include tablet {
      margin-bottom: 0px;
      flex-direction: column;
      @for $i from 1 through 4 {
        &.col-#{$i} {
          width: calc(((100% - #{($i - 1) * $grid-gap}) / #{$i}) * 1);
        }
      }
      &.col-3 {
        width: calc(((100% - #{2 * $grid-gap}) / 4) * 1);
      }
      &.col-4 {
        width: calc(((100% - #{3 * $grid-gap}) / 5) * 1);
      }
    }

    .image-container,
    .redactor {
      margin-right: 10px;
      @include tablet {
        margin-right: 0;
        margin-bottom: 30px;
      }
      @include desktop {
        margin-bottom: 44px;
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: 80px;
      width: 80px;
      min-width: 120px;
      @include tablet {
        height: 260px;
        width: auto;
      }
      .image {
        height: 100%;
      }
    }
    &.col-2 .image-container {
      max-width: 414px;
    }
    &.col-3 .image-container {
      max-width: 243px;
      max-height: 236px;
    }

    .block__text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-transform: uppercase;
      flex: 1;
      font-size: 16px;
      padding: 0 0 0 20px;
      @include tablet {
        align-items: center;
        width: 100%;
        padding: 0;
      }
    }
  }
}
