.newsletter {
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 2 / 8;
  }
  &__image {
    display: none;
    flex-direction: column;
    justify-content: center;
    grid-column: 9 / -2;
    @include tablet {
      display: flex;
    }
  }
}
