// Articleblocks
//
// Articleblocks
//
// Style guide: 3-components.articleblocks

.article-block {
  position: relative;
  background: $brand-color;
  &.block__background {
    &--black {
      background: $dark-border;
    }
    &--white {
      background: $white;
    }
    &--creme {
      background: $creme;
    }
    &--yellow {
      background: $brand-color;
    }
    &--dark-yellow {
      background: $assets-color;
    }
  }

  .article-block__content {
    grid-column: 3 / -3;
    width: 100%;
  }
}
