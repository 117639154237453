.article-overview {
  &__content {
    grid-column: 2/-2;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @include desktop-medium {
      padding-top: 60px;
      padding-bottom: 120px;
      display: grid;
      gap: $grid-gap;
      grid-template-columns: auto 384px;
    }
  }

  &__title {
    grid-column: 1 / span 1;
    width: max-content;
  }
  &__filter {
    grid-column: 2 / span 1;
  }
  &__results {
    grid-column: 1 / span 2;
  }

  // Title
  &__title,
  &__results .results__list__year {
    position: relative;
    display: inline-block;
    max-width: 80%;
    padding: 0px 40px calc(40px / 2) 0;
  }
  &__title .title,
  &__results .results__list__year .title {
    position: relative;
    display: inline-block;
    line-height: 0.8;
    z-index: 1;
  }
  &__title .title__shape,
  &__results .results__list__year .title__shape {
    position: absolute;
    left: 0;
    bottom: 0%;
    height: 40px;
    width: 100%;
    transform: scaleX(-1);
    z-index: 0;
  }

  &__filter {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 188px;
    margin: 40px 0 30px auto;
    z-index: 4;
    @include tablet {
      width: 216px;
      margin: 20px 0 20px auto;
    }
    @include desktop-medium {
      width: 254px;
      margin: auto 0 0 auto;
    }
    .v-select {
      font-size: 18px;
    }
  }
  &__filter__years {
    width: 100%;
  }
  &__filter__years.dropdown__group {
    min-width: unset !important;
    font-size: 16px;
    @include desktop-medium {
      font-size: 30px;
    }
  }
  &__filter__years.dropdown__group .vs__dropdown-toggle,
  &__filter__years.dropdown__group .vs__dropdown-menu {
    background: $creme;
  }

  &__filter__years .v-select {
    position: relative;
    display: inline-block;
    line-height: 0.8;
    z-index: 1;
  }
  &__results {
    display: flex;
    flex-direction: column;
  }
  &__results .results {
    &__list {
      list-style: none;
      padding: 0;
    }
    &__list li:last-of-type {
      margin-bottom: 0;
    }
    &__list__year {
      width: max-content;
      display: block;
      padding: 0px 0 20px 40px;
      margin: 30px 0 30px auto;
      @include tablet {
        margin: 100px 0 100px auto;
      }
      @include desktop-medium {
        margin: 60px 0 60px auto;
      }
      &:first-of-type {
        margin-top: 40px;
      }
    }
    &__list__year .title__shape {
      transform: none;
    }
  }
  &__list-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: none;
    padding: 0;
    margin: 40px 0 0 0;
    border: none;
  }
  &__list-button .button__line {
    display: block;
    flex: 1;
    height: 3px;
    background: $creme;
  }
  &__list-button .button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0 16px !important;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    cursor: pointer;
  }
  &__list-button .button__label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }
}
