// twoColumnThumbAndText
//
// Style guide: 3-components.contentblocks.twoColumnThumbAndText
.content-block--two-column-thumb-and-text {
  .columns {
    @include tablet {
      display: flex;
      gap: $grid-gap;
    }
  }

  .column {
    width: 100%;
  }
}
