// Header Homepage
//
// Markup:
// <div id="colin" class="content-block content-block--header-homepage grid-container padding--regular show-on-mobile block__background--yellow">
// <div class="content-block__content">
//       <p class="header__word--one extra-big--title">
//       Super
//     </p>
// 	<div class="image-container header__big-image">
// 		<picture class="image header__image  fit" data-iesrc="http://localhost:8080/nl/nl/actions/assets/generate-transform?transformId=1" data-alt="Star wars backgrounds 09">
// 			<img src="https://picsum.photos/1920/1200" width="1920" height="1200" style="" loading="lazy" decoding="async">
// 		</picture>
// 	</div>
//       <p class="header__word--two extra-big--title">
//       fast
//     </p>
//         <p class="header__word--three extra-big--title">
//       charging
//     </p>
//         <p class="header__word--four extra-big--title">
//       stations
//     </p>
// 	<div class="image-container header__small-image">
// 		<picture class="image header__image  fit" data-iesrc="http://localhost:8080/nl/nl/actions/assets/generate-transform?transformId=1" data-alt="Star wars backgrounds 09">
// 			<img src="https://picsum.photos/252/252" width="252" height="252" style="" loading="lazy" decoding="async">
// 		</picture>
// 	</div>
//       <div class="header__content h2">
//       <p>Experience Electric Freedom with charging that’s as fast as a coffee break.</p>
//     </div>
// </div>
// </div>
//
// Style guide: 3-components.contentblocks.headerNetworkDetail

.content-block--header-network-detail {
  .content-block__content {
    grid-column: 2 / -2;
    display: grid;
    gap: 0 $grid-gap--mobile;
    grid-template-columns: repeat(12, 1fr);
    word-break: normal;
    @include tablet {
      gap: 0 $grid-gap;
    }
  }

  .image-container {
    grid-column: 1 / span 12;
    grid-row: 4;
    @include tablet {
      grid-column: 4 / -1;
      grid-row: 3;
    }
    @include desktop {
      grid-row: 2;
    }
  }

  .header {
    &__title {
      &--one {
        grid-column: 1 / span 12;
        grid-row: 1;
        @include desktop {
          grid-column: 2 / -1;
        }
      }

      &--two {
        grid-column: 1 / span 12;
        grid-row: 2;
        @include desktop {
          grid-row: 3;
          grid-column: 1 / -2;
          text-align: right;
        }
      }
    }

    &__content {
      margin-top: 20px;
      grid-column: 1 / span 11;
      grid-row: 3;
      @include tablet {
        grid-column: 1 / span 8;
        grid-row: 4;
      }
      @include desktop {
        margin-top: 100px;
        grid-column: 3 / span 4;
      }
    }
  }
}
