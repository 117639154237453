// Typography
//
// Your standard font types
//
// Style guide: 2-base.typography

// Font-family
//
// Your standard font types
//
// Markup:
// <p class="styleguide-font-display {{modifier_class}}">Sora {{modifier_class}}</p>
//
// .bold - Render bold version of font
// .semi-bold - Render semi-bold version of font
// .medium - Render medium version of font
// .regular - Render regular version of font
// .light - Render light version of font
//
// Style guide: 2-base.typography.fonts

$font-fallback: Helvetica, sans-serif;
$font-bold: 'Sora extra-bold', $font-fallback;
$font-bold: 'Sora bold', $font-fallback;
$font-semi-bold: 'Sora semi-bold', $font-fallback;
$font-medium: 'Sora medium', $font-fallback;
$font-regular: 'Sora regular', $font-fallback;
$font-light: 'Sora light', $font-fallback;

@mixin font-extra-bold {
  font-family: 'Sora', $font-fallback;
  font-weight: 800;
}
@mixin font-bold {
  font-family: 'Sora', $font-fallback;
  font-weight: 700;
}
@mixin font-semi-bold {
  font-family: 'Sora', $font-fallback;
  font-weight: 600;
}
@mixin font-medium {
  font-family: 'Sora', $font-fallback;
  font-weight: 500;
}
@mixin font-regular {
  font-family: 'Sora', $font-fallback;
  font-weight: 400;
}
@mixin font-light {
  font-family: 'Sora', $font-fallback;
  font-weight: 300;
}

.styleguide-font-display {
  @include font-regular;
  font-size: 50px;
  margin-bottom: 0;
}
.bold {
  @include font-bold;
}
.semi-bold {
  @include font-semi-bold;
}
.medium {
  @include font-medium;
}
.regular {
  @include font-regular;
}
.light {
  @include font-light;
}

// Typography
//
// Your standard typography styling
//
// Markup:
// <p class="extra-big--title">Extra-big--title</p>
// <p class="big--title">Big--title</p>
// <p class="semi-big--title">Semi-big--title</p>
// <h1>Heading 1</h1>
// <h2>Heading 2</h2>
// <h3>Heading 3</h3>
// <h4>Heading 4</h4>
// <h5>Heading 5</h5>
// <h6>Heading 6</h6>
// </br>
// <p>Lorem ipsum dolor sit amet, <a href="#test">consectetur adipiscing</a> elit. Nulla <strong>placerat</strong>, erat ac pulvinar pharetra, nulla <u>dui iaculis</u> arcu, quis imperdiet urna nisl vel nulla. Nullam eros mi, pulvinar sed tortor vel, bibendum dapibus sapien. Ut venenatis ut purus eu ullamcorper. Ut magna lectus, ultrices sit amet nunc vitae, dapibus varius elit. In hac habitasse platea dictumst. Aenean laoreet turpis in porta sollicitudin. Morbi enim turpis, vulputate ut lacus eu, fringilla laoreet lorem. Nulla vestibulum nisi a urna sodales, vitae faucibus dolor pulvinar. Duis non maximus sapien, vel sagittis dolor. Aliquam id semper mi.</p>
// <p>Proin at elementum tellus. <i>Morbi viverra</i> vehicula sem, quis <em>feugiat nisi aliquam</em> ut. Maecenas vitae ultrices lectus, a rutrum massa. Maecenas mollis lectus sit amet nunc pellentesque, ut facilisis ex pulvinar. Nulla tincidunt lobortis nunc. Donec sagittis non est et eleifend. Ut commodo lacus ut lectus consectetur tempus. Duis laoreet, nibh et venenatis sollicitudin, sapien erat tincidunt dolor, a rhoncus leo lacus quis augue. In quis convallis libero, a posuere libero.</p>
// </br>
// <q>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla placerat, erat ac pulvinar pharetra, nulla dui iaculis arcu, quis imperdiet urna nisl vel nulla.</q>
// <blockquote>Proin at elementum tellus. Morbi viverra vehicula sem, quis feugiat nisi aliquam ut. Maecenas vitae ultrices lectus, a rutrum massa. Maecenas mollis lectus sit amet nunc pellentesque, ut facilisis ex pulvinar. Nulla tincidunt lobortis nunc. Donec sagittis non est et eleifend. Ut commodo lacus ut lectus consectetur tempus.
// Duis laoreet, nibh et venenatis sollicitudin, sapien erat tincidunt dolor, a rhoncus leo lacus quis augue.
// In quis convallis libero, a posuere libero.</blockquote>
// </br>
// <ul>
//   <li>Unorderd list item</li>
//   <li>Unorderd list item</li>
//   <li>Unorderd list item</li>
// </ul>
// </br>
// <ol>
//   <li>Orderd list item</li>
//   <li>Orderd list item</li>
//   <li>Orderd list item</li>
// </ol>
//
// Style guide: 2-base.typography.typography

body {
  @include font-light;
  font-size: 16px;
  line-height: 1.5;
  @include tablet {
    font-size: 18px;
  }
}

.extra-big--title,
.big--title,
.semi-big--title {
  margin: 0;
}

// Custom Headings
.extra-big--title {
  @include font-medium;
  font-size: 50px;
  line-height: 1;
  @include tablet {
    font-size: 70px;
  }
  @include desktop-big {
    font-size: 90px;
  }
}
.big--title {
  @include font-medium;
  font-size: 40px;
  line-height: 1;
  @include tablet {
    font-size: 70px;
  }
  @include desktop-big {
    font-size: 90px;
  }
}
.semi-big--title {
  @include font-medium;
  font-size: 30px;
  line-height: 1;
  @include tablet {
    font-size: 50px;
  }
  @include desktop-big {
    font-size: 70px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 1rem;
}

h1,
.h1 {
  @include font-medium;
  font-size: 20px;
  line-height: 1.15;
  @include tablet {
    font-size: 30px;
  }
  @include desktop {
    font-size: 50px;
    line-height: 1.1;
  }
}
h2,
.h2 {
  @include font-medium;
  font-size: 20px;
  line-height: 1.15;
  @include tablet {
    font-size: 30px;
  }
}
h3,
.h3 {
  @include font-medium;
  font-size: 16px;
  line-height: 1.15;
  @include tablet {
    font-size: 20px;
  }
}

h4,
.h4 {
  @include font-regular;
  font-size: 16px;
  line-height: 1.15;
  @include tablet {
    font-size: 20px;
  }
}
h5,
.h5 {
  @include font-regular;
  font-size: 16px;
  line-height: 1.15;
  @include tablet {
    font-size: 18px;
  }
}
h6,
.h6 {
  @include font-regular;
  font-size: 16px;
  line-height: 1.15;
  @include desktop {
    font-size: 18px;
  }
}

p,
ol,
ul,
q,
blockquote {
  margin: 0 0 0.5em 0;
}

ul,
ol {
  padding: 1em 1em 1em 50px;
  @include tablet {
    padding: 1em 1em 1em 100px;
  }
  @include desktop {
    padding: 1em 1em 1em 150px;
  }
}

q,
blockquote {
  border-left: 2px solid $creme;
  padding-left: 14px;
  border-radius: 2px;
  margin: 1em 0;
}

b,
strong {
  @include font-bold();
}
