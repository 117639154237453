// Buttons
//
// Your standard button suitable for clicking.
//
// Markup:
// <a href="#" class="button {{modifier_class}}">Link Button</a>
// <button class="button {{modifier_class}}">Button Element</button>
// <input type="button" class="button {{modifier_class}}" value="input[type='button']"/>
//
// :hover    - Button Hover state.
// :focus    - Button Focus state
// :actve    - Button Active state
// :disabled - Button Disabled state.
// .primary  - Indicates button is the primary action.
// .secondary  - Indicates button is the secondary action
//
// Style guide: 2-base.buttons
.freeform button,
.redactor button,
.button {
  display: inline-flex;
  justify-content: center;
  padding: 15px 50px;
  min-width: unset;
  width: auto;
  margin: 16px 16px 0 0;
  border-radius: 20px;
  border: 1px solid $black;
  background: $black;
  white-space: pre-line;
  color: $white;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.3s;
  &:not(.primary):not(.button--primary):not(.secondary):not(.button--secondary) {
    border: 1px solid $black;
    background: $black;
    color: $white;
  }

  &:last-of-type {
    margin-right: 0;
  }
  &:focus,
  &:active {
    outline: none;
    color: $yellow;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
    font-weight: 400;
  }
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
  &.primary {
    border: 1px solid $black;
    background: $black;
    color: $white;
    &:focus,
    &:active {
      color: $yellow;
    }
  }
  &.secondary,
  &--secondary {
    border: 1px solid $black;
    background: $creme;
    color: $black;
    padding-left: 25px;
    padding-right: 25px;
    &:focus,
    &:active {
      border: 2px solid $black;
    }
  }
}
