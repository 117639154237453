/*
AppStoreDownload

Markup:
<div class="content-block content-block--app-store-download grid-container padding--large show-on-mobile block__background--yellow">
<div class="content-block__content">
   <div class="block__header">
      <h2 class="block__title semi-big--title">Kijk mam, laden zonder handen!</h2>
      <div class="block__text redactor">
         <p>Bijna dan. Je zet autocharge aan in de app, inpluggen en klaar.</p>
      </div>
   </div>
   <div class="block__image">
      <div class="image-container ">
         <picture class="image  app-image">
            <img src="https://source.unsplash.com/random/664x428" width="664" height="428" style="" loading="lazy" decoding="async">
         </picture>
      </div>
      <div class="block__appstore appstore appstore--limited">
         <div class="block__appstore__content">
            <div class="block__appstore__qr">
               <a href="https://apps.apple.com/nl/app/fastned/id1485702761" target="_blank">
                  <div class="image-container ">
                     <picture class="image  content__qr-code transparent fit" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/Icons/_100xAUTO_crop_center-center_10_none/onlink_to_tdpc2k_small.png" data-alt="Onlink to tdpc2k small">
                        <img src="https://source.unsplash.com/random/126x126" width="126" height="126" style="" loading="lazy" decoding="async">
                     </picture>
                  </div>
               </a>
            </div>
            <div class="block__appstore__text">
               <p class="block__appstore__title">Download the app</p>
               <div class="redactor block__appstore__redactor">
                  <p>Gebruik de camera van je telefoon om de gratis Fastned app te downloaden</p>
                  <p>Beschikbaar voor <a href="https://apps.apple.com/nl/app/fastned/id1485702761" target="_blank" rel="noreferrer noopener">iOS</a> en <a href="https://play.google.com/store/apps/details?id=nl.fastned.my&amp;hl=nl&amp;gl=US" target="_blank" rel="noreferrer noopener">Android</a></p>
               </div>
            </div>
         </div>
         <div class="block__appstore__link">
            <a href="https://apps.apple.com/nl/app/fastned/id1485702761" target="_blank" class="button button--secondary">Download the app</a>
         </div>
      </div>
   </div>
</div>

Style guide: 3-components.contentblocks.appStoreDownload
*/

.content-block {
  &--app-store-download &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &--app-store-download .block {
    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 663px;
      margin: 0 auto 20px;
      text-align: center;
    }
    &__title {
      margin-bottom: 20px;
      @include tablet {
        margin-bottom: 40px;
      }
    }
    &__image {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    &__image .image-container {
      justify-content: center;
    }
    &__image .app-image {
      @include tablet {
        width: calc((100% / 8) * 6);
      }
    }
    &__appstore {
      margin-top: $grid-gap;
      @include tablet {
        align-items: flex-end;
      }
      @include desktop {
        width: calc((100% / 8) * 5);
        max-width: 527px;
        align-items: center;
        align-self: flex-end;
        margin-top: -36px;
      }
    }
  }
}
