// Header Mission
//
// Markup:
// <div class="content-block content-block--header-homepage grid-container padding--regular show-on-mobile block__background--yellow">
//    <div class="content-block__content">
//       <h2 class="seo-title">Super snelle laad stations</h2>
//       <p class="header__word--one big--title">
//          Super
//       </p>
//       <div class="image-container header__big-image">
//          <picture class="image header__image  fit" data-iesrc="http://localhost:8080/nl/nl/actions/assets/generate-transform?transformId=1" data-alt="Star wars backgrounds 09">
//             <img src="https://picsum.photos/1427/1076" width="1427" height="1076" style="" loading="lazy" decoding="async">
//          </picture>
//       </div>
//       <div class="header__extra-content">
//          <p class="header__word--two extra-big--title">
//             snelle
//          </p>
//          <p class="header__word--three extra-big--title">
//             laad
//          </p>
//          <p class="header__word--four extra-big--title">
//             stations
//          </p>
//          <div class="image-container header__small-image">
//             <picture class="image header__image  fit" data-iesrc="http://localhost:8080/nl/nl/actions/assets/generate-transform?transformId=1" data-alt="Star wars backgrounds 09">
//                <img src="https://picsum.photos/235/205" width="235" height="205" style="" loading="lazy" decoding="async">
//             </picture>
//          </div>
//          <div class="header__content">
//             <div class="h2">
//                <p>Ervaar Electric Freedom met opladen dat zo snel gaat als een koffiepauze.</p>
//             </div>
//          </div>
//       </div>
//    </div>
//    <label class="dev-label">Header homepage</label>
// </div>
//
// Style guide: 3-components.contentblocks.headerMission

.content-block--header-mission {
  .content-block__content {
    grid-column: 2 / -2;
    display: grid;
    gap: 0 $grid-gap--mobile;
    grid-template-columns: repeat(12, 1fr);
    word-break: normal;
    @include tablet {
      gap: 0 $grid-gap;
    }
  }
  .header {
    &__image {
      padding: 0;
    }
    &__big-image {
      grid-column: span 12;
      @include desktop {
        grid-column: 3/ -3;
      }
    }
    &__word {
      &--one,
      &--two {
        margin: 18px 0;
        line-height: 0.7;
        z-index: 1;
        grid-column: span 12;
        @include tablet {
          margin: 12px 0;
        }
        @include desktop {
          grid-column: 3/ -3;
          margin: 52px 0;
        }
      }
      &--two {
        text-align: right;
      }
    }
  }
}
