// twoColumnMasonry
//
// Markup:
// <div class="content-block content-block--two-column-masonry grid-container padding--regular show-on-mobile block__background--yellow">
//    <div class="content-block__content masonry__content {{modifier_class}}">
//       <div class="image-container masonry__image">
//          <picture class="image   transparent" data-iesrc="https://np-fastned-staging.s3-eu-west-1.amazonaws.com/Test-content/_100xAUTO_crop_center-center_10_none/range-anxiety.png" data-alt="Range anxiety">
//           <img src="https://source.unsplash.com/random/763x444" width="763" height="444" style="" loading="lazy" decoding="async">
//          </picture>
//       </div>
//       <div class="masonry__side-text">
//          <div class="redactor">
//             <p><strong>When your neighbour says:</strong></p>
//             <p>EV's lack range, and there are not enough charging stations to enjoy long trips.</p>
//          </div>
//       </div>
//       <div class="masonry__text">
//          <div class="redactor">
//             <p><strong>You say:</strong></p>
//             <p>Even the shortest range EVs can travel more than twice the daily driving average. You should look at charging as different options on a really good plant-based menu. From slow charging poles at parking lots, wall boxes at hotels to super fast charging stations on the highway. Some dude even drove from the Netherlands to New Zealand. That's 100.450 kilometres with a clog on the pedal.</p>
//          </div>
//       </div>
//    </div>
//    <label class="dev-label">Two column masonry</label>
// </div>
//
// .align--right  - Align content right
//
// Style guide: 3-components.contentblocks.twoColumnMasonry

.content-block--two-column-masonry .masonry {
  &__content {
    display: flex;
    flex-direction: column;
    @include tablet {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas:
        'image  image image image image   .     .  side  side  side  side  side'
        'text   text  text  text  text  text  text  text  text  text  text  .';
      grid-gap: 40px $grid-gap;
    }
    @include desktop {
      grid-template-columns: repeat(8, 1fr);
      grid-template-areas:
        'image image image  .    side  side  side  side'
        'text  text  text  text  text  text   .     .  ';
      grid-gap: 80px $grid-gap;
    }
  }
  &__image,
  &__side-text,
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__image {
    grid-area: image;
    width: 50%;
    @include tablet {
      width: 100%;
    }
  }
  &__side-text {
    grid-area: side;
    width: 50%;
    margin-left: auto;
    @include tablet {
      width: 100%;
    }
  }
  &__text {
    width: 100%;
    grid-area: text;
  }

  &__content.align--right {
    .masonry__text {
      @include tablet {
        text-align: right;
      }
    }
    @include tablet {
      grid-template-areas:
        'side  side  side  side  side   .      .   image  image image image image'
        '  .   text  text  text  text  text  text  text  text  text  text  text';
    }
    @include desktop {
      grid-template-areas:
        'side  side  side  side   .    image image image'
        '   .     .  text  text  text  text  text  text';
    }
  }
}
