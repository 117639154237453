// Header How to
//
// Markup:
// <div id="aye" class="content-block content-block--header-how-to grid-container padding--regular show-on-mobile block__background--yellow">
//   <div class="content-block__content">
//     <p class="header__word--one big--title">
//       How
//     </p>
//     <p class="header__word--two big--title">
//       to
//     </p>
//     <div class="image-container header__big-image">
//       <picture class="image header__image  " data-iesrc="http://localhost:8080/nl/nl/actions/assets/generate-transform?transformId=1" data-alt="Star wars backgrounds 09">
//         <img src="https://picsum.photos/1920/1200" width="1920" height="1200" style="" loading="lazy" decoding="async">
//       </picture>
//     </div>
//     <p class="header__word--three big--title">
//       fast
//     </p>
//     <p class="header__word--four big--title">
//       charge?
//     </p>
//   </div>
//   <label class="dev-label">
//     Header how to
//   </label>
// </div>
//
// Style guide: 3-components.contentblocks.headerHowTo

.content-block {
  &--header-how-to &__content {
    grid-column: 2 / -2;
    display: grid;
    gap: 0 $grid-gap--mobile;
    grid-template-columns: repeat(12, 1fr);
    word-break: normal;
    @include tablet {
      gap: 0 $grid-gap;
    }
  }
  &--header-how-to .header {
    &__image {
      padding: 0;
    }
    &__big-image {
      grid-column: span 12;
      @include tablet {
      }
      @include desktop {
        grid-column: 3/ -3;
      }
    }
    &__word {
      &--one,
      &--two,
      &--three,
      &--four {
        padding: 0 0 5px 0;
      }
      &--one {
        z-index: 1;
        grid-column: 1 / span 6;
        overflow: hidden;
        @include tablet {
          grid-column: 1 / span 3;
          transform: translateY(-0.25em);
        }
        @include desktop {
          grid-column: 3 / span 3;
        }
      }
      &--two {
        z-index: 1;
        text-align: right;
        grid-column: span 6 / -1;
        overflow: hidden;
        @include tablet {
          transform: translateY(0.15em);
        }
        @include desktop {
          grid-column: span 5 / -3;
        }
      }
      &--three {
        z-index: 1;
        overflow: hidden;
        text-align: left;
        @include tablet {
          transform: translateY(-100%);
        }
        &.show--half {
          grid-column: 1 / span 6;
          @include desktop {
            grid-column: 3 / span 3;
          }
        }
        &.show--full {
          grid-column: 1 / -1;
          @include desktop {
            grid-column: 1 / -3;
          }
        }
      }
      &--four {
        z-index: 1;
        text-align: right;
        overflow: hidden;
        @include desktop {
          transform: translate(12.5%);
        }
        &.show--half {
          grid-column: span 6 / -1;
          @include tablet {
            grid-column: span 6 / -1;
          }
          @include desktop {
            grid-column: span 5 / -3;
            transform: translate(12.5%);
          }
        }
        &.show--full {
          grid-column: 1 / -1;
          @include desktop {
            grid-column: 1 / -3;
          }
        }
      }
    }
  }
}
